import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {Box} from '@mui/joy';
import Body from 'src/components/Shared/Body';
import Typography from '@mui/joy/Typography';
import {Paths} from 'src/utils/constants';
import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';

export enum NotFoundType {
    Mod = 'Mod',
    Page = 'Page'
}

interface Props {
    notFoundType: NotFoundType;
}

const ModOrPageNotFound: FC<Props> = ({notFoundType}) => {
    return (
        <Box sx={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <Body boxGap={7}>
                <BrowserNotSupportedIcon sx={{fontSize: 75, color: 'blue'}}/>
                <Typography
                    level="h1"
                    fontSize="x-large"
                    fontWeight="lg"
                    padding="15px"
                    borderRadius="md"
                >
                    The {notFoundType.toString()} Could Not Be Found
                </Typography>
                <Typography
                    level="h1"
                    fontSize="x-large"
                    fontWeight="lg"
                    padding="15px"
                    borderRadius="md"
                >
                    <Link to={Paths.Home}>Home</Link>
                </Typography>
            </Body>
        </Box>
    );
};

export default ModOrPageNotFound