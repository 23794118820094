import React, {ChangeEvent, FC, useEffect, useRef} from 'react';
import {Input, Radio, RadioGroup, Sheet} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {
    updateVoiceGender,
    VoiceGender
} from "src/slices/wordsSlice";
import {saveSubmitWhenEnterKeyIsPressed, saveUserName, setValueOfSetFocusOnUsername} from "src/slices/settingsSlice";

export enum LocalSettingsUI_Direction {
    column = 'column',
    row = 'row'
}

interface Props {
    direction: LocalSettingsUI_Direction;
}

export const LocalSettings: FC<Props> = ({direction}) => {
    const voiceGender = useAppSelector((state: RootState) => state.words.voiceGender);
    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);
    const userName = useAppSelector((state: RootState) => state.settings.userName);
    const setFocusOnUsername = useAppSelector((state: RootState) => state.settings.setFocusOnUsername);

    const appDispatch = useAppDispatch();

    const refUserName = useRef<HTMLInputElement>(null);

    useEffect(() => {
        refUserName.current?.focus()
    }, []);

    useEffect(() => {
        if (setFocusOnUsername) {
            refUserName.current?.focus()
            appDispatch(setValueOfSetFocusOnUsername(false))
            appDispatch(saveUserName(''))
        }
    }, [setFocusOnUsername]);

    return (
        <>
            <Sheet
                sx={{
                    display: 'flex',
                    borderRadius: 'sm',
                    p: 2,
                    alignItems: 'center'
                }}
            >
                <Typography
                    level="h4"
                    paddingRight="15px"
                >
                    Please enter your Name:
                </Typography>
                <Input
                    slotProps={{
                        input: {
                            ref: refUserName
                        },
                    }}
                    value={userName}
                    size="lg"
                    onChange={(event) => appDispatch(saveUserName(event.target.value))}
                    spellCheck="false"
                />
            </Sheet>
            <div style={{display: 'flex', flexDirection: direction, gap: '25px'}}>
                <Sheet
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        boxShadow: 'sm',
                        borderRadius: 'sm',
                        p: 2,
                        width: '450px',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        level="h4"
                        paddingRight="15px"
                    >
                        Spelling Voice Gender:
                    </Typography>
                    <RadioGroup
                        size="lg"
                        orientation="horizontal"
                        value={voiceGender} name="radio-buttons-group"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const selectedValue = event.target.value as VoiceGender;
                            appDispatch(updateVoiceGender(selectedValue));
                        }}
                    >
                        <Radio value="female" label="Female"/>
                        <Radio value="male" label="Male"/>
                    </RadioGroup>
                </Sheet>
                <Sheet
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        boxShadow: 'sm',
                        borderRadius: 'sm',
                        p: 2,
                        width: '450px',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        level="h4"
                        paddingRight="15px"
                    >
                        Submit Answer On 'Enter':
                    </Typography>
                    <RadioGroup
                        size="lg"
                        orientation="horizontal"
                        value={submitWhenEnterKeyIsPressed} name="radio-buttons-group"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            appDispatch(saveSubmitWhenEnterKeyIsPressed(event.target.value));
                        }}
                    >
                        <Radio value="yes" label="Yes"/>
                        <Radio value="no" label="No"/>
                    </RadioGroup>
                </Sheet>
            </div>
        </>
    )
}
