import React, {FC} from "react";
import {Snackbar} from "@mui/joy";

interface Props {
    onClose: () => void;
}

export const MobileAccessNotSupported: FC<Props> = (props: Props) => {
    return (
        <Snackbar
            size="lg"
            variant="solid"
            color="warning"
            anchorOrigin={{vertical: 'top', horizontal: 'left'}}
            autoHideDuration={3000}
            open={true}
            onClose={() => {
                props.onClose()
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                paddingLeft: '0px',
                paddingRight: '0px'
            }}
        >
            Mobile access to StudyMods is<br/>not supported at this time.<br/>
            <br/>
            Please visit the site on your<br/>laptop or desktop computer.
        </Snackbar>
    )
}
