import React, {CSSProperties, FC, Fragment, useEffect, useRef, useState} from 'react';
import Button from '@mui/joy/Button';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import {IMod} from "@backend/mongoose.gen";
import {CssVarsProvider, extendTheme, IconButton, ListDivider, Menu, MenuItem, Snackbar, Tooltip} from '@mui/joy';
import {ModalPopup} from "./ModalPopup";
import {formatDate} from "../utils/functions";
import {useAppDispatch} from "src/app/hooks";
import {Page, setAssessmentPageTitle, setAssessmentPageType, update} from "src/slices/pageSlice";
import {saveSelectedSpellingInfo, setAssessmentCompletedBeforeStudying} from "src/slices/wordsSlice";
import {ModalPopupData} from "./ModalPopup";
import {ModTypes} from "src/utils/constants";
import {ArrowDropDown} from "@mui/icons-material";
import {setCurrentBook} from "src/slices/bookSlice";
import {z} from "zod";
import {IBookModSchema} from "src/zodSchemas";
import {isMobile} from 'react-device-detect';
import {MobileAccessNotSupported} from "src/components/Shared/MobileAccessNotSupported";

interface Props {
    cardInfo: IMod | z.infer<typeof IBookModSchema>
}

export const ModCard: FC<Props> = ({cardInfo}) => {
    const [openShareMenu, setOpenShareMenu] = useState(false);
    const actionRef = useRef<() => void | null>(null);
    const anchorRef = useRef<HTMLDivElement>(null);

    // These Options are used for Both Books and Spelling Words
    const SHARE = 'Share';
    const COPY_STUDY_LINK_ONLY = 'Copy Study Link Only';
    const COPY_TEST_LINK_ONLY = 'Copy Test Link Only';
    const COPY_STUDY_LINK_WITH_TITLE = 'Copy Study Link With Title';
    const COPY_TEST_LINK_WITH_TITLE = 'Copy Test Link With Title';
    const COPY_STUDY_AND_TEST_LINKS_ONLY = 'Copy Study and Test Links Only';
    const COPY_STUDY_AND_TEST_LINKS_WITH_TITLE = 'Copy Study and Test Links With Title';

    const shareMenuOptions = [
        SHARE,
        COPY_STUDY_LINK_ONLY,
        COPY_TEST_LINK_ONLY,
        COPY_STUDY_LINK_WITH_TITLE,
        COPY_TEST_LINK_WITH_TITLE,
        COPY_STUDY_AND_TEST_LINKS_ONLY,
        COPY_STUDY_AND_TEST_LINKS_WITH_TITLE
    ];

    const [open, setOpen] = useState(false);
    const [showLinkHasBeenCopiedMsg, setShowLinkHasBeenCopiedMsg] = useState(false);
    const [showNotAvailableInMobileMsg, setShowNotAvailableInMobileMsg] = useState(false);
    const [copyConfirmationMsg, setCopyConfirmationMsg] = useState('')
    const [items] = useState<ModalPopupData[]>(() => {
        if ((cardInfo as IMod).words !== undefined) {
            return (cardInfo as IMod).words.map(wordObj => ({
                primary_text: wordObj.word!,
                secondary_text: wordObj.alternate_spelling!
            }))
        } else if ((cardInfo as z.infer<typeof IBookModSchema>).chapters !== undefined) {
            return (cardInfo as z.infer<typeof IBookModSchema>).chapters.map(chapter => ({
                primary_text: chapter.title!
            }))
        }

        return []
    })

    const [startButtonToolTipText, setStartButtonToolTipText] = useState<string>("")
    const [viewButtonText, setViewButtonText] = useState<string>("")
    const [viewButtonToolTipText, setViewButtonToolTipText] = useState<string>("")
    const [studyButtonToolTipText, setStudyButtonToolTipText] = useState<string>("")

    const appDispatch = useAppDispatch();

    const baseTheme = extendTheme();
    const darkTheme = extendTheme({
        colorSchemes: {
            dark: baseTheme.colorSchemes.dark
        },
    });

    const gridContainerStyle: CSSProperties = {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(80px, max-content))',
        gap: '0px',
        marginTop: '10px'
    };

    const gridItem: CSSProperties = {
        whiteSpace: "nowrap",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }

    //todo: enable the hover to show the hidden text in the div
    // const div: hover: CSSProperties = {
    // overflow: visible;
    // }

    const gridItemFirstAndThirdStyle: CSSProperties = {
        ...gridItem,
        textAlign: 'right'
    };

    const gridItemSecondAndFourthStyle: CSSProperties = {
        ...gridItem,
        textAlign: 'left',
        marginLeft: '5px'
    };

    useEffect(() => {
        if (cardInfo.type! === ModTypes.spelling) {
            setStartButtonToolTipText("Start Spelling Test")
            setViewButtonText("Words")
            setViewButtonToolTipText("View Spelling Words")
            setStudyButtonToolTipText("Study the Words in this Mod")
        } else if (cardInfo.type! === ModTypes.chapter) {
            setStartButtonToolTipText("Start Chapter Test")
            setViewButtonText("Topics")
            setViewButtonToolTipText("View Chapter Topics")
            setStudyButtonToolTipText("Study the Topics in this Mod")
        } else if (cardInfo.type! === ModTypes.book) {
            setStartButtonToolTipText("Start a Chapter Test")
            setViewButtonText("Chapters")
            setViewButtonToolTipText("View Chapters in Book")
            setStudyButtonToolTipText("Study the Chapters in this Mod")
        }
    }, []);

    const handleClick = () => {
        setOpenShareMenu(!openShareMenu)
    };

    const handleMenuItemClick = async (
        index: number,
    ) => {
        const modType = cardInfo.type === ModTypes.book ? "book" : "words";
        const theModLinkHasBeenCopied = "The Mod link has been copied.";
        const theModLinksHaveBeenCopied = "The Mod links have been copied.";
        const studyLink = `${window.location.href}study/${modType}/id/${cardInfo._id}`
        const testLink = `${window.location.href}${modType === ModTypes.book ? `test/${modType}` : 'test'}/id/${cardInfo._id}`

        switch (shareMenuOptions[index + 1]) {
            case COPY_STUDY_LINK_ONLY:
                await navigator.clipboard.writeText(`${studyLink}`);
                setCopyConfirmationMsg(theModLinkHasBeenCopied)
                break
            case COPY_TEST_LINK_ONLY:
                await navigator.clipboard.writeText(`${testLink}`);
                setCopyConfirmationMsg(theModLinkHasBeenCopied)
                break
            case COPY_STUDY_LINK_WITH_TITLE:
                await navigator.clipboard.writeText(`Study '${cardInfo.title}': ${studyLink}`);
                setCopyConfirmationMsg(theModLinkHasBeenCopied)
                break
            case COPY_TEST_LINK_WITH_TITLE:
                await navigator.clipboard.writeText(`Test for '${cardInfo.title}': ${testLink}`);
                setCopyConfirmationMsg(theModLinkHasBeenCopied)
                break
            case COPY_STUDY_AND_TEST_LINKS_ONLY:
                await navigator.clipboard.writeText(`${studyLink}\n\n${testLink}`)
                setCopyConfirmationMsg(theModLinksHaveBeenCopied)
                break
            case COPY_STUDY_AND_TEST_LINKS_WITH_TITLE:
                await navigator.clipboard.writeText(`Study '${cardInfo.title}': ${studyLink}\n\n\`Test for '${cardInfo.title}': ${testLink}`)
                setCopyConfirmationMsg(theModLinksHaveBeenCopied)
        }

        setShowLinkHasBeenCopiedMsg(true);
        setTimeout(() => {
            setShowLinkHasBeenCopiedMsg(false);
        }, 2000);
        setOpenShareMenu(false);
    };

    return (
        <>
            <CssVarsProvider theme={darkTheme}>
                <Card
                    variant="outlined"
                    sx={{
                        width: 335
                    }}
                >
                    <CardContent>
                        <Typography level="title-lg">{cardInfo.title}</Typography>
                        <Typography level="title-md">{cardInfo.desc}</Typography>
                        <Typography level="body-sm" style={gridContainerStyle}>
                            <span style={gridItemFirstAndThirdStyle}>Organization:</span>
                            <span style={gridItemSecondAndFourthStyle}>{cardInfo.org}</span>
                            <span style={gridItemFirstAndThirdStyle}>Subject:</span>
                            <span style={gridItemSecondAndFourthStyle}>{cardInfo.subject}</span>
                            <span style={gridItemFirstAndThirdStyle}>Grade:</span>
                            <span style={gridItemSecondAndFourthStyle}>{cardInfo.grade}</span>
                            <span style={gridItemFirstAndThirdStyle}>Added On:</span>
                            <span style={gridItemSecondAndFourthStyle}>{formatDate(cardInfo.added_on!)}</span>
                        </Typography>
                    </CardContent>
                    <CardActions buttonFlex="1">
                        <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                            <Tooltip
                                title={startButtonToolTipText}
                                variant="solid">
                                <Button variant="solid" color="primary" onClick={() => {
                                    if (isMobile) {
                                        setShowNotAvailableInMobileMsg(true)
                                        return
                                    }

                                    appDispatch(update(Page.ASSESSMENT))
                                    appDispatch(setAssessmentPageTitle(cardInfo.title))
                                    appDispatch(setAssessmentPageType(cardInfo.type))

                                    if (cardInfo.type === ModTypes.spelling) {
                                        appDispatch(setAssessmentCompletedBeforeStudying(true))
                                        appDispatch(saveSelectedSpellingInfo({
                                            spellingID: cardInfo._id!.toString(),
                                            title: cardInfo.title,
                                            words: "words" in cardInfo ? cardInfo.words : []
                                        }))
                                    } else if (cardInfo.type === ModTypes.book) {
                                        appDispatch(setAssessmentCompletedBeforeStudying(true))
                                        appDispatch(setCurrentBook(cardInfo as z.infer<typeof IBookModSchema>))
                                    }
                                }}>Start</Button>
                            </Tooltip>
                            <Tooltip
                                title={viewButtonToolTipText}
                                variant="solid">
                                <Button
                                    onClick={
                                        () => {
                                            if (isMobile) {
                                                setShowNotAvailableInMobileMsg(true)
                                                return
                                            }

                                            setOpen(true)
                                        }
                                    }
                                >{viewButtonText}</Button>
                            </Tooltip>
                            <ButtonGroup
                                sx={{'--ButtonGroup-radius': '0px'}}
                                color="primary"
                                variant='solid'
                                ref={anchorRef}
                                aria-label="split button"
                            >
                                <Button onClick={handleClick}>{shareMenuOptions[0]}</Button>
                                <IconButton
                                    aria-controls={open ? 'split-button-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-label="share"
                                    aria-haspopup="menu"
                                    onMouseDown={() => {
                                        // @ts-ignore
                                        actionRef.current! = () => setOpenShareMenu(!openShareMenu);
                                    }}
                                    onKeyDown={() => {
                                        // @ts-ignore
                                        actionRef.current! = () => setOpenShareMenu(!openShareMenu);
                                    }}
                                    onClick={() => {
                                        actionRef.current?.();
                                    }}
                                >
                                    <ArrowDropDown/>
                                </IconButton>
                            </ButtonGroup>
                            <Menu open={openShareMenu} onClose={() => setOpenShareMenu(false)}
                                  anchorEl={anchorRef.current}>
                                {shareMenuOptions.filter((option, index) => option && (index !== 0)).map((option, index) => (
                                    <Fragment key={option}>
                                        <MenuItem
                                            key={option}
                                            onClick={() => handleMenuItemClick(index)}
                                        >
                                            {option}
                                        </MenuItem>
                                        {(index === 1 || index === 3) && <ListDivider/>}
                                    </Fragment>
                                ))}
                            </Menu>
                            <Tooltip
                                title={studyButtonToolTipText}
                                variant="solid">
                                <Button onClick={
                                    () => {
                                        if (isMobile) {
                                            setShowNotAvailableInMobileMsg(true)
                                            return
                                        }

                                        if ("words" in cardInfo) {
                                            // Spelling Words are being Studied
                                            appDispatch(setAssessmentCompletedBeforeStudying(false))
                                            appDispatch(saveSelectedSpellingInfo({
                                                spellingID: cardInfo._id.toString(),
                                                title: cardInfo.title,
                                                words: "words" in cardInfo ? cardInfo.words : []
                                            }))
                                            appDispatch(update(Page.STUDY_WORDS))
                                        } else if ("chapters" in cardInfo) {
                                            // Book is being Studied
                                            appDispatch(setCurrentBook(cardInfo as z.infer<typeof IBookModSchema>))
                                            appDispatch(update(Page.SELECT_CHAPTER_TO_STUDY))
                                        }
                                    }
                                }
                                >Study</Button>
                            </Tooltip>
                        </ButtonGroup>
                    </CardActions>
                </Card>
                <ModalPopup
                    open={open}
                    closeDialog={() => setOpen(false)}
                    title={cardInfo.title}
                    items={items}
                />
            </CssVarsProvider>
            {
                (() => {
                    if (showLinkHasBeenCopiedMsg) {
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color="warning"
                                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                autoHideDuration={1750}
                                open={true}
                                onClose={() => {
                                    setShowLinkHasBeenCopiedMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                }}
                            >
                                {copyConfirmationMsg}
                            </Snackbar>
                        )
                    }
                })()
            }
            {
                (() => {
                    if (showNotAvailableInMobileMsg) {
                        return (
                            <MobileAccessNotSupported
                                onClose={() => {
                                    setShowNotAvailableInMobileMsg(false);
                                }}
                            />

                        )
                    }
                })()
            }
        </>
    );
}