import React, {FC, useEffect, useState} from 'react';
import {Header} from 'src/components/Header';
import {AssessmentResultFooter} from "src/components/Shared/AssessmentResultFooter";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {Box, List, ListItem} from '@mui/joy';
import Typography from "@mui/joy/Typography";
import {IChapterAssessment, resetChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {Page, update} from "src/slices/pageSlice";
import Button from "@mui/joy/Button";
import {resizeButtonSX, wordListPadding} from "src/utils/ui";
import {useChapterMgr} from "src/hooks/useChapterMgr";
import {ChapterResult, ModTypes} from "src/utils/constants";
import {completeChapterTest} from "src/utils/api-service";
import {completeSectionTest} from "src/utils/book-records";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";

export const ChapterAssessmentResult: FC = () => {
    const [currentQuestion, setCurrentQuestion] = useState('Click an Answer to see its corresponding Question')
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1)
    const [initializationComplete, setInitializationComplete] = useState(false)

    const title = useAppSelector((state: RootState) => state.chapter.retakeOfIncorrectAndSkippedAnswers ? `Incorrect and Skipped Answers of ${state.chapter.title}` : state.chapter.title);
    const results = useAppSelector((state: RootState) => state.chapterAssessment.data);
    const chapterTestID = useAppSelector((state: RootState) => state.chapter.chapterTestID);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);
    const chapterID = useAppSelector((state: RootState) => state.chapter.chapterID);
    const userName = useAppSelector((state: RootState) => state.settings.userName);

    const appDispatch = useAppDispatch();
    const {assessmentIsValid, getChapterResult, getChapterResultCounts} = useChapterMgr();
    const resultCounts = getChapterResultCounts(results)

    const showQuestion = (index: number) => {
        setCurrentQuestion(results[index].question)
        setCurrentQuestionIndex(index)
    };

    const separateResultsBySection = (items: IChapterAssessment[]): IChapterAssessment[][] => {
        const grouped: { [key: string]: IChapterAssessment[] } = {};

        items.forEach(item => {
            if (!grouped[item.section_id]) {
                grouped[item.section_id] = [];
            }
            grouped[item.section_id].push(item);
        });

        return Object.values(grouped);
    };

    useEffect(() => {
        if (!initializationComplete) {
            // Used to make sure code runs only once in DEV and PRD builds.
            setInitializationComplete(true)
        }
    }, []);

    useEffect(() => {
        if (initializationComplete) {
            const arraysSeparatedBySection = separateResultsBySection(results);

            arraysSeparatedBySection.forEach(sectionArray => {
                const sectionResultCounts = getChapterResultCounts(sectionArray)
                const totalResultCount = sectionResultCounts.correct + sectionResultCounts.incorrect + sectionResultCounts.skipped + sectionResultCounts.typo

                const results = completeSectionTest(
                    userName,
                    currentBook._id!.toString(), chapterID, sectionArray[0].section_id, {
                        percent_correct: sectionResultCounts.correct / totalResultCount,
                        percent_incorrect: sectionResultCounts.incorrect / totalResultCount,
                        percent_skipped: sectionResultCounts.skipped / totalResultCount,
                        percent_typo: sectionResultCounts.typo / totalResultCount
                    })

                localStorage.setItem(results!.dayKey, results!.updatedData);
            })

            completeChapterTest(chapterTestID,
                (response) => {
                    if (response.data.message === 'Chapter Test Record Not Found') {
                        console.log(response.data.message)
                    }
                },
                (error: any) => console.error("Error:", error)
            )
        }
    }, [initializationComplete]);

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <Header/>
            <Title title={title}/>
            <Description description={'Chapter Assessment Results'}/>
            {
                (() => {
                    if (assessmentIsValid(results)) {
                        return (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                {wordListPadding()}
                                <Box
                                    sx={{
                                        width: '80%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                        marginBottom: '40px'
                                    }}
                                >
                                    <Typography level="body-lg"
                                                padding="10px"
                                                sx={{
                                                    borderRadius: 'sm',
                                                    outline: '2px solid #2d792a'
                                                }}
                                    >
                                        {currentQuestion}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: '80%',
                                        display: 'flex',
                                        gap: 2,
                                        flexWrap: 'wrap',
                                        justifyContent: 'center'
                                    }}
                                >
                                    {results.map((result, index) => (
                                        <div key={index}>
                                            <List
                                                size='sm'
                                                variant="soft"
                                                sx={{
                                                    borderRadius: 'sm',
                                                    fontSize: 'lg',
                                                    outline: index === currentQuestionIndex ? '2px solid #2d792a' : null
                                                }}
                                            >
                                                <ListItem>
                                                    {
                                                        (() => {
                                                            if (getChapterResult(result) === ChapterResult.INCORRECT) {
                                                                return <div
                                                                    style={{cursor: 'pointer', display: 'flex'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography
                                                                        color="danger">
                                                                        {result.incorrect_answer}
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Typography
                                                                        color="success">
                                                                        ({result.correct_answer})
                                                                    </Typography>
                                                                </div>;
                                                            }
                                                            if (getChapterResult(result) === ChapterResult.TYPO) {
                                                                return <div
                                                                    style={{cursor: 'pointer', display: 'flex'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography
                                                                        color="danger">
                                                                        {result.typo_in_answer}
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Typography
                                                                        color="success">
                                                                        ({result.correct_answer})
                                                                    </Typography>
                                                                </div>;
                                                            }
                                                            if (getChapterResult(result) === ChapterResult.SKIPPED) {
                                                                return <div
                                                                    style={{cursor: "pointer", display: 'flex'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography
                                                                        color="warning">
                                                                        skipped
                                                                    </Typography>
                                                                    &nbsp;
                                                                    <Typography
                                                                        color="success">
                                                                        ({result.correct_answer})
                                                                    </Typography>
                                                                </div>;
                                                            }
                                                            return (
                                                                <div
                                                                    style={{cursor: 'pointer'}}
                                                                    onClick={() => showQuestion(index)}
                                                                >
                                                                    <Typography color="success">
                                                                        {result.correct_answer}
                                                                    </Typography>
                                                                </div>
                                                            );
                                                        })()
                                                    }
                                                </ListItem>
                                            </List>
                                        </div>
                                    ))}
                                </Box>
                                {wordListPadding()}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'center',
                                        height: '15vh'
                                    }}
                                >                                    {
                                    (() => {
                                        if (resultCounts[ChapterResult.INCORRECT] === 0 && resultCounts[ChapterResult.SKIPPED] === 0) {
                                            // All Answers were correct, but there may have been typos.
                                            return (
                                                <Button
                                                    sx={resizeButtonSX()}
                                                    variant="solid" color="primary"
                                                    onClick={
                                                        () => {
                                                            appDispatch(update(Page.HOME))
                                                        }
                                                    }
                                                >
                                                    Home
                                                </Button>
                                            )
                                        }

                                        // Some Questions were answered correctly or skipped
                                        return (
                                            <Button
                                                variant="solid" color="primary"
                                                sx={resizeButtonSX()}
                                                onClick={
                                                    () => {
                                                        appDispatch(update(Page.STUDY_SECTIONS))
                                                    }
                                                }
                                            >
                                                {(resultCounts.incorrect + resultCounts.typo + resultCounts.skipped) === 1 ? 'Study Section' : 'Study Sections'}
                                            </Button>
                                        )
                                    })()
                                }
                                </Box>
                            </Box>
                        )
                    }

                    return (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center'
                                }}
                            >
                                <Typography
                                    color="danger"
                                    fontSize="x-large"
                                >
                                    Not enough questions were answered to complete the assessment.
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: "center",
                                    alignItems: 'center',
                                    height: '15vh'
                                }}
                            >
                                <Button
                                    variant="solid" color="primary"
                                    sx={resizeButtonSX()}
                                    onClick={
                                        () => {
                                            appDispatch(resetChapterAssessment())
                                            appDispatch(update(Page.ANSWER_QUESTION))
                                        }
                                    }
                                >
                                    Restart the Assessment
                                </Button>
                            </Box>
                        </>
                    )
                })()
            }
            <AssessmentResultFooter correct={resultCounts[ChapterResult.CORRECT]}
                                    incorrect={resultCounts[ChapterResult.INCORRECT]}
                                    skipped={resultCounts[ChapterResult.SKIPPED]}
                                    type={ModTypes.chapter}
                                    typo={resultCounts[ChapterResult.TYPO]}
            />
        </div>
    );
}