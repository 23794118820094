import {z} from "zod";
import {createZodSchema} from "src/utils/create-zod-schema"
import {
    IBookMod,
    IBookModChapter,
    IBookModChapterSection,
    IBookModChapterSectionParagraph,
    IBookModChapterSectionParagraphMultiple_choice,
    IBookModChapterSectionParagraphQuestions_and_answer,
    IBookModStudyRecord,
    IBookModStudyRecordBook, IBookModStudyRecordBookChapter, IBookModStudyRecordBookChapterSection,
    IBookModTestRecord, IBookModTestRecordBook, IBookModTestRecordBookChapter, IBookModTestRecordBookChapterSection,
    IModRecord
} from "@backend/mongoose.gen"
import ObjectID from "bson-objectid";

// Create the base Zod schemas
const BaseIBookModSchema = createZodSchema<IBookMod>();
const BaseIBookModChapterSchema = createZodSchema<IBookModChapter>();
const BaseIBookModChapterSectionSchema = createZodSchema<IBookModChapterSection>();
const BaseIBookModChapterSectionParagraphSchema = createZodSchema<IBookModChapterSectionParagraph>();
const BaseIBookModChapterSectionParagraphMultipleChoiceSchema = createZodSchema<IBookModChapterSectionParagraphMultiple_choice>()
const BaseIBookModChapterSectionParagraphQuestionsAndAnswerSchema = createZodSchema<IBookModChapterSectionParagraphQuestions_and_answer>()

// Data stored for a Book Study AND a Book Test Record: Study Record > Books Array > Chapters Array > Sections Array
const BaseIBookModStudyRecordSchema = createZodSchema<IBookModStudyRecord>()
const BaseIBookModTestRecordSchema = createZodSchema<IBookModTestRecord>()
const BaseIBookModStudyRecordBookSchema = createZodSchema<IBookModStudyRecordBook>()
const BaseIBookModTestRecordBookSchema = createZodSchema<IBookModTestRecordBook>()
const BaseIBookModStudyRecordBookChapterSchema = createZodSchema<IBookModStudyRecordBookChapter>()
const BaseIBookModTestRecordBookChapterSchema = createZodSchema<IBookModTestRecordBookChapter>()
const BaseIBookModStudyRecordBookChapterSectionSchema = createZodSchema<IBookModStudyRecordBookChapterSection>()
const BaseIBookModTestRecordBookChapterSectionSchema = createZodSchema<IBookModTestRecordBookChapterSection>()

export const BaseIModRecordSchema = createZodSchema<IModRecord>()

export const IBookModChapterSectionParagraphQuestionsAndAnswerSchema = BaseIBookModChapterSectionParagraphQuestionsAndAnswerSchema.extend({
    _id: z.string().min(1),
    question: z.string().min(1, "Question must not be empty"),
    answer: z.string().min(1, "Answer must not be empty")
})

export const IBookModStudyRecordBookChapterSectionSchema = BaseIBookModStudyRecordBookChapterSectionSchema.extend({
    _id: z.string().min(1),
    title: z.string().min(1),
    complete: z.boolean().optional()
})

export const IBookModTestRecordBookChapterSectionSchema = BaseIBookModTestRecordBookChapterSectionSchema.extend({
    _id: z.string().min(1),
    title: z.string().min(1),
    percent_correct: z.number(),
    percent_incorrect: z.number(),
    percent_skipped: z.number(),
    percent_typo: z.number(),
    counter: z.number()
})

export const IBookModStudyRecordBookChapterSchema = BaseIBookModStudyRecordBookChapterSchema.extend({
    _id: z.string().min(1),
    title: z.string().min(1),
    sections: z.array(IBookModStudyRecordBookChapterSectionSchema).min(1)
})

export const IBookModTestRecordBookChapterSchema = BaseIBookModTestRecordBookChapterSchema.extend({
    _id: z.string().min(1),
    title: z.string().min(1),
    sections: z.array(IBookModTestRecordBookChapterSectionSchema).min(1),
    percent_correct: z.number(),
    percent_incorrect: z.number(),
    percent_skipped: z.number(),
    percent_typo: z.number(),
    counter: z.number()
})

export const IBookModStudyRecordBookSchema = BaseIBookModStudyRecordBookSchema.extend({
    _id: z.string().min(1),
    title: z.string().min(1),
    chapters: z.array(IBookModStudyRecordBookChapterSchema).min(1)
})

export const IBookModTestRecordBookSchema = BaseIBookModTestRecordBookSchema.extend({
    _id: z.string().min(1),
    title: z.string().min(1),
    chapters: z.array(IBookModTestRecordBookChapterSchema).min(1),
    percent_correct: z.number(),
    percent_incorrect: z.number(),
    percent_skipped: z.number(),
    percent_typo: z.number(),
    counter: z.number()
})

export const IBookModStudyRecordSchema = BaseIBookModStudyRecordSchema.extend({
    _id: z.instanceof(ObjectID),
    user: z.string(),
    added_on: z.date(),
    books: z.array(IBookModStudyRecordBookSchema).min(1)
})

export const IBookModTestRecordSchema = BaseIBookModTestRecordSchema.extend({
    _id: z.instanceof(ObjectID),
    user: z.string(),
    added_on: z.date(),
    books: z.array(IBookModTestRecordBookSchema).min(1)
})

export const IBookModChapterSectionParagraphMultipleChoiceSchema = BaseIBookModChapterSectionParagraphMultipleChoiceSchema.extend({
    _id: z.string().min(1),
    question: z.string().trim().min(1, "Question must not be empty"),
    answer: z.string().trim().min(1, "Answer must not be empty"),
    options: z.array(z.string().trim().min(1)).min(3, "There must be 3 Options").max(3, "There must be 3 Options")
})

export const IBookModChapterSectionParagraphSchema = BaseIBookModChapterSectionParagraphSchema.extend({
    _id: z.string().min(1),
    text: z.string().min(1, "Paragraph text must not be empty"),
    multiple_choices: z.array(IBookModChapterSectionParagraphMultipleChoiceSchema).min(1, "Paragraph must have at least one multiple choice"),
    questions_and_answers: z.array(IBookModChapterSectionParagraphQuestionsAndAnswerSchema).min(1, "Paragraph must have at least one question and answer")
})

export const IBookModChapterSectionSchema = BaseIBookModChapterSectionSchema.extend({
    _id: z.string().min(1),
    title: z.string().trim().min(1, "Title must be present"),
    paragraphs: z.array(IBookModChapterSectionParagraphSchema).min(1, "Section must have at least one paragraph"),
})

export const IBookModChapterSchema = BaseIBookModChapterSchema.extend({
    _id: z.string().min(1),
    title: z.string().trim().min(1, "Title must be present"),
    sections: z.array(IBookModChapterSectionSchema).min(1, "Chapter must have at least one section"),
    available: z.boolean()
})

export const IBookModSchema = BaseIBookModSchema.extend({
    _id: z.instanceof(ObjectID).optional(),
    academic_year: z.string().trim().min(1, "Academic year must be present"),
    org: z.string().trim().min(1, "Organization must be present"),
    grade: z.number().min(4, "Grade must be 4 or above").max(12, "Grade must be 12 or less"),
    subject: z.string().trim().min(1, "Subject must be present"),
    serial_no: z.number().min(1, "Serial No must be present"),
    title: z.string().trim().min(1, "Title must be present"),
    desc: z.string().optional(),
    type: z.string().trim().min(1, "Type must be present"),
    chapters: z.array(IBookModChapterSchema).min(1, "Book must have at least one chapter"),
    available: z.boolean(),
    added_on: z.date().optional(),
    notes: z.string().optional()
})