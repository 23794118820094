import React, {FC, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Link, useLocation} from "react-router-dom";
import Typography from '@mui/joy/Typography';
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {Page, update} from "src/slices/pageSlice";
import {pauseCountdown, resumeCountdown} from "src/slices/wordsSlice";
import {RootState} from "src/app/store";
import {SMModalDialog} from "src/components/SMModalDialog";
import {Paths} from "src/utils/constants";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import {Divider} from "@mui/joy";

export const Header: FC = () => {
    const [showCloseDialog, setShowCloseDialog] = useState<boolean>(false);

    const [dialogMsg, setDialogMsg] = useState('')
    const [dialogConfirmButtonMsg, setDialogConfirmButtonMsg] = useState('')

    const location = useLocation();
    const currentPath = location.pathname;

    const navigate = useNavigate();
    const appDispatch = useAppDispatch();
    const currentPage = useAppSelector((state: RootState) => state.pages.value);

    const {stopSpeaking} = useSharedMgr();

    return (
        <div style={{position: "fixed", top: 0, width: "100%", zIndex: 10}}>
            <Typography level="h1" fontWeight="lg" fontSize="xl" padding="10px" bgcolor="blue"
                        sx={{color: "white"}}
            >
                {
                    (() => {
                        if (currentPath === Paths.ImportWords || currentPath === Paths.ImportBook) return (
                            <div style={{width: "fit-content", cursor: "pointer"}}>
                                <Link to="/">
                                    <Typography level="h1" fontWeight="lg" fontSize="xl"
                                                sx={{color: "white", textDecoration: 'underline'}}>
                                        StudyMods
                                    </Typography>
                                </Link>
                            </div>
                        )

                        return (
                            <div style={{display: "flex"}}>
                                <div style={{width: "fit-content", cursor: "pointer", marginRight: "15px"}}
                                     onClick={() => {
                                         if (currentPage === Page.SHOW_WORD || currentPage === Page.ANSWER_QUESTION) {
                                             setDialogMsg("Are you sure you want to leave the assessment?")
                                             setDialogConfirmButtonMsg("Leave Assessment")
                                             appDispatch(pauseCountdown());
                                             setShowCloseDialog(true)
                                         } else if (currentPage === Page.STUDY_WORDS || currentPage === Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER || currentPage === Page.TYPE_CORRECT_ANSWER_IN_CHAPTER || currentPage === Page.CHOOSE_CORRECT_SPELLING || currentPage === Page.STUDY_SECTIONS) {
                                             setDialogMsg("Are you sure you want to exit the study session?")
                                             setDialogConfirmButtonMsg("Exit Study Session")
                                             setShowCloseDialog(true)
                                         } else {
                                             appDispatch(update(Page.HOME));
                                         }
                                     }
                                     }
                                >
                                    <Typography level="h1" fontWeight="lg" fontSize="xl"
                                                sx={{color: "white"}}>
                                        StudyMods
                                    </Typography>
                                </div>
                                <Divider orientation="vertical" sx={{bgcolor: "white", width: "2px"}}/>
                                <div style={{width: "fit-content", cursor: "pointer", marginLeft: "15px"}}
                                     onClick={
                                         () => {
                                             appDispatch(update(Page.BOOK_RECORDS));
                                         }
                                     }>
                                    <Typography level="h1" fontWeight="lg" fontSize="xl"
                                                sx={{color: "white"}}>
                                        Book Records
                                    </Typography>
                                </div>

                            </div>
                        )
                    })()
                }
            </Typography>
            <SMModalDialog
                showDialog={showCloseDialog}
                cancelAction={() => {
                    setShowCloseDialog(false)

                    if (currentPage === Page.SHOW_WORD || currentPage === Page.ANSWER_QUESTION) {
                        appDispatch(resumeCountdown())
                    }
                }}
                confirmationAction={() => {
                    setShowCloseDialog(false)

                    if (currentPage === Page.SHOW_WORD || currentPage === Page.ANSWER_QUESTION) {
                        appDispatch(resumeCountdown())
                    }

                    stopSpeaking()
                    navigate(Paths.Home)
                    appDispatch(update(Page.HOME));
                }}
                assessmentPageTitle={"Please Confirm"}
                bodyMsg={dialogMsg}
                confirmBtnMsg={dialogConfirmButtonMsg}
            />
        </div>
    )
}