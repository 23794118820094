import React, {FC, useState} from "react";
import {Header} from "src/components/Header";
import Body from "src/components/Shared/Body";
import Title from "src/components/Shared/Title";
import Button from "@mui/joy/Button";
import {RichTreeView, TreeItem, TreeItemProps} from "@mui/x-tree-view";
import {
    Article,
    AutoStories,
    Check,
    Clear,
    CheckBoxOutlineBlank,
    Keyboard,
    CheckBox,
    Note,
    Person,
    Today,
    AdsClick
} from "@mui/icons-material";
import {Box, ToggleButtonGroup} from "@mui/joy";
import {
    IBookModStudyRecordBookChapterSchema,
    IBookModStudyRecordBookChapterSectionSchema,
    IBookModStudyRecordBookSchema,
    IBookModTestRecordBookChapterSchema,
    IBookModTestRecordBookChapterSectionSchema,
    IBookModTestRecordBookSchema
} from "src/zodSchemas";
import {z} from "zod";
import {TreeViewItem} from "src/utils/interfaces";
import Typography from "@mui/joy/Typography";

export const BookRecords: FC = () => {
    // The following Constants are used to display icons in front of Sections, Chapters, Books and Percentages
    const COMPLETE = 'COMPLETE';
    const BOOK = 'BOOK';
    const CHAPTER = 'CHAPTER';
    const SECTION = 'SECTION';
    const DATE = 'DATE';
    const USER = 'USER';
    const CORRECT = 'CORRECT';
    const INCORRECT = 'INCORRECT';
    const SKIPPED = 'SKIPPED';
    const TYPO = 'TYPO';

    const [recordType, setRecordType] = useState('');
    const [studyRecords, setStudyRecords] = useState<TreeViewItem[] | null>(null)
    const [testRecords, setTestRecords] = useState<TreeViewItem[] | null>(null)

    const extractDatesFromLocalStorage = (recordType: string) => {
        const records = Object.keys(localStorage);
        const datePattern = new RegExp(`^${recordType}/(\\d{2}-[A-Za-z]{3}-\\d{4})(?:/[a-zA-Z0-9_ ]+)?$`);

        return records
            .filter((key) => datePattern.test(key))
            .map((key) => {
                const match = key.match(datePattern);
                return match ? match[1] : null;
            })
            .filter(Boolean); // Remove null or undefined values
    };

    const getUsersFromLocalStorage = (recordType: string): string[] => {
        return Object.entries(localStorage)
            .filter(([key]) => key.toLowerCase().includes(recordType.toLowerCase())) // Case-insensitive match for keys
            .map(([, record]) => {
                try {
                    return JSON.parse(record).user; // Extract user as is
                } catch {
                    return null; // Skip invalid entries
                }
            })
            .filter((user, index, self) =>
                user && self.findIndex(u => u?.toLowerCase() === user.toLowerCase()) === index // Deduplicate case-insensitively
            )
            .filter(Boolean) // Filter out null/undefined
            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())); // Sort alphabetically
    };

    const buildTreeNode = (label: string, id: string, children: TreeViewItem[] = []): TreeViewItem => ({
        id,
        label,
        children,
    });

    const getStudyData: () => TreeViewItem[] = () => {
        const dates = extractDatesFromLocalStorage('bookStudyRecord');
        const users = getUsersFromLocalStorage('bookStudyRecord');

        const studyData: TreeViewItem[] = [];

        users.forEach((user, userIndex) => {
            const userChildren: TreeViewItem[] = [];
            const processedDates = new Set<string>();

            dates
                .sort((a, b) => Date.parse(b!) - Date.parse(a!))
                .forEach((date, dateIndex) => {
                    if (processedDates.has(date!)) return;

                    const matchingData = Object.entries(localStorage)
                        .filter(([key]) => key.includes("bookStudyRecord"))
                        .map(([, record]) => {
                            try {
                                return JSON.parse(record);
                            } catch {
                                return null;
                            }
                        })
                        .filter((entry) => entry && entry.added_on === date && entry.user === user);

                    const bookSet = new Set<string>();
                    const chapterSet = new Set<string>();
                    const sectionSet = new Set<string>();

                    const dateChildren = matchingData.flatMap((entry) =>
                        entry.books
                            .filter((book: z.infer<typeof IBookModStudyRecordBookSchema>) => {
                                if (bookSet.has(book._id)) return false;
                                bookSet.add(book._id);
                                return true;
                            })
                            .map((book: z.infer<typeof IBookModStudyRecordBookSchema>) => {
                                const chapterChildren = book.chapters
                                    .filter((chapter: z.infer<typeof IBookModStudyRecordBookChapterSchema>) => {
                                        if (chapterSet.has(chapter._id)) return false;
                                        chapterSet.add(chapter._id);
                                        return true;
                                    })
                                    .map((chapter: z.infer<typeof IBookModStudyRecordBookChapterSchema>) => {
                                        const sectionChildren = chapter.sections
                                            .filter((section: z.infer<typeof IBookModStudyRecordBookChapterSectionSchema>) => {
                                                if (sectionSet.has(section._id)) return false;
                                                sectionSet.add(section._id);
                                                return true;
                                            })
                                            .map((section: z.infer<typeof IBookModStudyRecordBookChapterSectionSchema>) =>
                                                buildTreeNode(
                                                    section.title,
                                                    section?.complete
                                                        ? `${SECTION}${COMPLETE}${userIndex}-${dateIndex}-${section._id}`
                                                        : `${SECTION}${userIndex}-${dateIndex}-${section._id}`
                                                )
                                            );

                                        const chapterComplete = sectionChildren.every((section) =>
                                            section.id.startsWith(`${SECTION}${COMPLETE}`)
                                        );

                                        return buildTreeNode(
                                            chapter.title,
                                            chapterComplete
                                                ? `${CHAPTER}${COMPLETE}${userIndex}-${dateIndex}-${book._id}-${chapter._id}`
                                                : `${CHAPTER}${userIndex}-${dateIndex}-${book._id}-${chapter._id}`,
                                            sectionChildren
                                        );
                                    });

                                const bookComplete = chapterChildren.every((chapter) =>
                                    chapter.id.startsWith(`${CHAPTER}${COMPLETE}`)
                                );

                                return buildTreeNode(
                                    book.title,
                                    bookComplete
                                        ? `${BOOK}${COMPLETE}${userIndex}-${dateIndex}-${book._id}`
                                        : `${BOOK}${userIndex}-${dateIndex}-${book._id}`,
                                    chapterChildren
                                );
                            })
                    );

                    const dateComplete = dateChildren.every((book) =>
                        book.id.startsWith(`${BOOK}${COMPLETE}`)
                    );

                    if (dateChildren.length > 0) {
                        userChildren.push(
                            buildTreeNode(
                                date!,
                                dateComplete
                                    ? `${DATE}${COMPLETE}${userIndex}-${dateIndex}`
                                    : `${DATE}${userIndex}-${dateIndex}`,
                                dateChildren
                            )
                        );

                        processedDates.add(date!);
                    }
                });

            if (userChildren.length > 0) {
                studyData.push(buildTreeNode(user!, `${USER}-user-${userIndex}`, userChildren));
            }
        });

        setStudyRecords(studyData)
        return studyData;
    };

    const getTestData: () => TreeViewItem[] = () => {
        const dates = extractDatesFromLocalStorage('bookTestRecord');
        const users = getUsersFromLocalStorage('bookTestRecord');

        const buildMetricsNodes = (node: z.infer<typeof IBookModTestRecordBookChapterSectionSchema> | z.infer<typeof IBookModTestRecordBookChapterSchema> | z.infer<typeof IBookModTestRecordBookSchema>, contextId: string): TreeViewItem[] => [
            buildTreeNode(`Percent Correct: ${(node.percent_correct * 100).toFixed(1)}%`, `${CORRECT}${contextId}-correct`),
            buildTreeNode(`Percent Incorrect: ${(node.percent_incorrect * 100).toFixed(1)}%`, `${INCORRECT}${contextId}-incorrect`),
            buildTreeNode(`Percent Skipped: ${(node.percent_skipped * 100).toFixed(1)}%`, `${SKIPPED}${contextId}-skipped`),
            buildTreeNode(`Percent Typo: ${(node.percent_typo * 100).toFixed(1)}%`, `${TYPO}${contextId}-typo`),
        ];

        const testData: TreeViewItem[] = [];

        users.forEach((user, userIndex) => {
            const userChildren: TreeViewItem[] = [];
            const processedDates = new Set<string>();

            dates
                .sort((a, b) => Date.parse(b!) - Date.parse(a!))
                .forEach((date, dateIndex) => {
                    if (processedDates.has(date!)) return;

                    const matchingData = Object.entries(localStorage)
                        .filter(([key]) => key.includes("bookTestRecord"))
                        .map(([, record]) => {
                            try {
                                return JSON.parse(record) as {
                                    user: string;
                                    added_on: string;
                                    books: z.infer<typeof IBookModTestRecordBookSchema>[];
                                };
                            } catch {
                                return null;
                            }
                        })
                        .filter((entry) => entry && entry.added_on === date && entry.user === user);

                    const processedChapters = new Set<string>();
                    const processedSections = new Set<string>();

                    const dateChildren = matchingData.flatMap((entry) =>
                        entry!.books.map((book: z.infer<typeof IBookModTestRecordBookSchema>) => {
                            const bookContextId = `${userIndex}-${dateIndex}-${book._id}`;
                            const chapterChildren = book.chapters
                                .filter((chapter: z.infer<typeof IBookModTestRecordBookChapterSchema>) => {
                                    if (processedChapters.has(chapter._id)) return false;
                                    processedChapters.add(chapter._id);
                                    return true;
                                })
                                .map((chapter: z.infer<typeof IBookModTestRecordBookChapterSchema>) => {
                                    const chapterContextId = `${bookContextId}-${chapter._id}`;
                                    const sectionChildren = chapter.sections
                                        .filter((section: z.infer<typeof IBookModTestRecordBookChapterSectionSchema>) => {
                                            if (processedSections.has(section._id)) return false;
                                            processedSections.add(section._id);
                                            return true;
                                        })
                                        .map((section: z.infer<typeof IBookModTestRecordBookChapterSectionSchema>) =>
                                            buildTreeNode(
                                                `${section.title} (${section.counter} ${section.counter === 1 ? 'Test' : 'Tests'})`,
                                                `${SECTION}${chapterContextId}-${section._id}`,
                                                buildMetricsNodes(section, `${chapterContextId}-${section._id}`)
                                            )
                                        );

                                    return buildTreeNode(
                                        `${chapter.title} (${chapter.counter} ${chapter.counter === 1 ? 'Section Test' : 'Section Tests'})`,
                                        `${CHAPTER}${chapterContextId}`,
                                        [...buildMetricsNodes(chapter, chapterContextId), ...sectionChildren]
                                    );
                                });

                            return buildTreeNode(
                                `${book.title} (${book.counter} ${book.counter === 1 ? 'Section Test' : 'Section Tests'})`,
                                `${BOOK}${bookContextId}`,
                                [...buildMetricsNodes(book, bookContextId), ...chapterChildren]
                            );
                        })
                    );

                    if (dateChildren.length > 0) {
                        userChildren.push(
                            buildTreeNode(date!, `${DATE}${userIndex}-${dateIndex}`, dateChildren)
                        );
                    }

                    processedDates.add(date!);
                });

            if (userChildren.length > 0) {
                testData.push(buildTreeNode(user!, `${USER}-user-${userIndex}`, userChildren));
            }
        });

        setTestRecords(testData)
        return testData;
    };

    const getNodeImage = (itemId: string) => {
        const personElem = <Person sx={{fontSize: 20, color: 'blue'}}/>
        const completeElem = <CheckBox sx={{fontSize: 20, color: 'green'}}/>
        const dateElem = <Today sx={{fontSize: 20, color: 'blue'}}/>
        const bookElem = <AutoStories sx={{fontSize: 20, color: 'blue'}}/>
        const chapterElem = <Article sx={{fontSize: 20, color: 'blue'}}/>
        const noteElem = <Note sx={{fontSize: 20, color: 'blue'}}/>
        const checkElem = <Check sx={{fontSize: 20, color: 'green'}}/>
        const clearElem = <Clear sx={{fontSize: 20, color: 'red'}}/>
        const checkBoxOutlineBlankElem = <CheckBoxOutlineBlank sx={{fontSize: 20, color: 'gray'}}/>
        const keyBoardElem = <Keyboard sx={{fontSize: 20, color: 'gray'}}/>

        if (itemId.startsWith(USER)) return (personElem);
        if (itemId.startsWith(`${DATE}${COMPLETE}`)) return (<>{dateElem}{completeElem}</>);
        if (itemId.startsWith(`${DATE}`)) return (dateElem);
        if (itemId.startsWith(`${BOOK}${COMPLETE}`)) return (<>{bookElem}{completeElem}</>);
        if (itemId.startsWith(`${BOOK}`)) return (bookElem);
        if (itemId.startsWith(`${CHAPTER}${COMPLETE}`)) return (<>{chapterElem}{completeElem}</>);
        if (itemId.startsWith(`${CHAPTER}`)) return (chapterElem);
        if (itemId.startsWith(`${SECTION}${COMPLETE}`)) return (<>{noteElem}{completeElem}</>);
        if (itemId.startsWith(`${SECTION}`)) return (noteElem);
        if (itemId.startsWith(`${CORRECT}`)) return (checkElem);
        if (itemId.startsWith(`${INCORRECT}`)) return (clearElem);
        if (itemId.startsWith(`${SKIPPED}`)) return (checkBoxOutlineBlankElem);
        if (itemId.startsWith(`${TYPO}`)) return (keyBoardElem);

        return null
    };

    return (
        <div
            style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'top',
                alignItems: 'left',
                flexDirection: 'column'
            }}
        >
            <Header/>
            <Title title={'Book Records'}/>
            <Body>
                <ToggleButtonGroup value={recordType}
                                   onChange={(_, newValue) => {
                                       setRecordType(newValue!);
                                   }}>
                    <Button value='study'>Study Records</Button>
                    <Button value='test'>Test Records</Button>
                </ToggleButtonGroup>
                {
                    <div style={{display: 'flex', height: '100%', width: '100%', justifyContent: 'flex-start'}}>
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                                paddingLeft: '30px',
                                paddingRight: '30px',
                                paddingBottom: '20px',
                                paddingTop: '20px',
                                overflowY: 'scroll',
                                boxSizing: 'border-box',
                                display: recordType === 'study' || recordType === 'test' ? 'flex' : 'none',
                            }}
                        >
                            <RichTreeView
                                key='study'
                                sx={{
                                    display: recordType === 'study' ? 'block' : 'none',
                                    width: '100%'
                                }}
                                items={recordType === 'study' ? studyRecords ? studyRecords : getStudyData() : []}
                                slots={{
                                    item: (props: TreeItemProps) =>
                                        <TreeItem
                                            {...props}
                                            label={
                                                <div
                                                    style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                                    {getNodeImage(props.itemId)}
                                                    <span>{props.label}</span>
                                                </div>
                                            }
                                        />
                                }}
                            />
                            <RichTreeView
                                key='test'
                                sx={{
                                    display: recordType === 'test' ? 'block' : 'none',
                                    width: '100%'
                                }}
                                items={recordType === 'test' ? testRecords ? testRecords : getTestData() : []}
                                slots={{
                                    item: (props: TreeItemProps) =>
                                        <TreeItem
                                            {...props}
                                            label={
                                                <div
                                                    style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                                                    {getNodeImage(props.itemId)}
                                                    <span>{props.label}</span>
                                                </div>
                                            }
                                        />
                                }}/>
                        </Box>
                        <Box
                            sx={{
                                display: recordType === '' || recordType === null ? 'flex' : 'none',
                                height: '100%',
                                width: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '20px'
                            }}
                        >
                            <AdsClick sx={{fontSize: 75, color: 'blue'}}/>
                            <Typography
                                level="h1"
                                fontSize="x-large"
                                fontWeight="lg"
                                padding="15px"
                                borderRadius="md"
                                display="contents"
                                bgcolor="blue"
                            >
                                <span style={{display: 'block', textAlign: 'center'}}>Please click on a Button<br/>above to see the Records.</span>
                            </Typography>
                        </Box>
                    </div>
                }

            </Body>
        </div>
    )
}