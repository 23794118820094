import React, {useState} from 'react';
import {getAvailableMods} from "../utils/api-service";
import {IBookMod, IMod} from "@backend/mongoose.gen";
import {ModCard} from "src/components/ModCard";
import {Box, LinearProgress, Sheet} from "@mui/joy";
import Body from "src/components/Shared/Body";
import Typography from "@mui/joy/Typography";
import {Header} from "src/components/Header";
import {WifiOff} from "@mui/icons-material";

export const ModCardContainer: React.FC = () => {
    const [mods, setMods] = React.useState<IMod[] | IBookMod[]>();
    const [modsRetrievalFailure, setModsRetrievalFailure] = useState(false)

    const modsRetrieved = (modsData: IMod[]) => {
        setMods(modsData);
    };

    const retrievalFailure = (error: string) => {
        setModsRetrievalFailure(true)
        console.log('error: ', error)
    };

    React.useEffect(() => {
        setModsRetrievalFailure(false)
        getAvailableMods({available: true}, modsRetrieved, retrievalFailure)
    }, [])

    const gridLayoutStyles: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '15px'
    };

    const containerStyle: React.CSSProperties = {
        marginTop: '60px',
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
    };

    return (
        <>
            {
                (() => {
                    if (modsRetrievalFailure) return (
                        <Body>
                            <Box sx={{
                                width: "100vw",
                                height: "100vh",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                gap: '20px'
                            }}>
                                <WifiOff sx={{fontSize: 75, color: 'blue'}}/>
                                <Typography
                                    level="h1"
                                    fontSize="x-large"
                                    fontWeight="lg"
                                    padding="15px"
                                    borderRadius="md"
                                    display="contents"
                                >
                                    <span>The Mods could not be loaded.</span>
                                    <span>Please check your internet connection.</span>
                                </Typography>
                            </Box>
                        </Body>
                    )

                    if (mods && mods.length > 0) return (
                        <>
                            <Header/>
                            <div style={containerStyle}>
                                <div style={gridLayoutStyles}>
                                    {Object.values(mods).map(mod => <ModCard key={mod._id} cardInfo={mod}/>)}
                                </div>
                            </div>
                        </>
                    )

                    return (
                        <Sheet sx={{
                            display: 'flex',
                            height: '100vh',
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            alignItems: 'center',
                        }}>
                            <LinearProgress/>
                        </Sheet>
                    )
                })()
            }
        </>
    )
};