import React, {FC, useEffect, useState} from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from "@mui/joy/Typography";
import {Box, List, ListItem, ListItemButton, ListItemDecorator, Radio, RadioGroup, Snackbar} from "@mui/joy";
import {formatDate, newObjectId, popParagraphData} from "src/utils/functions";
import Button from "@mui/joy/Button";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {Page, setAssessmentPageTitle, setAssessmentPageType, update} from "src/slices/pageSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {resetSpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {resizeButtonSX} from "src/utils/ui";
import {RootState} from "src/app/store";
import {
    saveSelectedSpellingInfo,
    setAssessmentCompletedBeforeStudying
} from "src/slices/wordsSlice";
import {ModTypes} from "src/utils/constants";
import {resetChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {useParams} from 'react-router-dom';
import {
    getMod,
    startChapterTest,
    startSpellingTest,
    useLazyGetBookQuery,
    useLazyGetChapterQuery
} from "src/utils/api-service";
import {IBookMod, IBookModChapter, IMod} from "@backend/mongoose.gen";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import ObjectID from "bson-objectid";
import {saveChapterTestID, saveSelectedChapterInfo} from "src/slices/chapterSlice";
import {saveSpellingTestID} from "src/slices/wordsSlice";
import {
    LocalSettings,
    LocalSettingsUI_Direction
} from "src/components/Shared/LocalSettings";
import {setCurrentBook} from "src/slices/bookSlice";
import {setCurrentSectionID} from "src/slices/chapterSlice";
import {z} from "zod";
import {BaseIModRecordSchema, IBookModSchema} from "src/zodSchemas";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import {saveUserName, setValueOfSetFocusOnUsername} from "src/slices/settingsSlice";
import Card from "@mui/joy/Card";
import {isMobile} from "react-device-detect";
import {MobileAccessNotSupported} from "src/components/Shared/MobileAccessNotSupported";
import {Header} from "src/components/Header";

export const AssessmentHomePage: FC = () => {
    const [selectedChapterID, setSelectedChapterID] = useState('')
    const [snackBarMsg, setSnackBarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [selectedSections, setSelectedSections] = useState<string[]>([])
    const [showNotAvailableInMobileMsg, setShowNotAvailableInMobileMsg] = useState(false);

    const title = useAppSelector((state: RootState) => state.pages.assessmentPageTitle);
    const type = useAppSelector((state: RootState) => state.pages.assessmentPageType);
    const spellingID = useAppSelector((state: RootState) => state.words.spellingID);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);
    const userName = useAppSelector((state: RootState) => state.settings.userName);

    const [IDWasInURL, setIDWasInURL] = useState(false)
    const [modRetrievalFailure, setModRetrievalFailure] = useState(false)

    const {id} = useParams();
    const appDispatch = useAppDispatch();
    const {setNavigationPathToHomeIfNeeded} = useSharedMgr();

    const [getChapter, {data: chapterData, fulfilledTimeStamp: chapterFulfilledTimeStamp}] = useLazyGetChapterQuery();
    const [getBook, {
        data: bookData,
        isError: getBookError,
        fulfilledTimeStamp: bookFulfilledTimeStamp
    }] = useLazyGetBookQuery();

    // Mod Data was retrieved via URL
    const modRetrieved = (modData: IBookMod | IMod) => {
        appDispatch(setAssessmentPageTitle(modData.title))
        appDispatch(setAssessmentPageType(modData.type))

        if (modData.type === ModTypes.spelling) {
            appDispatch(setAssessmentCompletedBeforeStudying(true))
            appDispatch(saveSelectedSpellingInfo({
                spellingID: modData._id.toString(),
                title: modData.title,
                words: "words" in modData ? modData.words : []
            }))
        } else if (modData.type === ModTypes.chapter) {
            popParagraphData(modData as IBookMod, appDispatch, true);
        }
    };

    const retrievalFailure = (error: string) => {
        console.log(`Error: ${error}`)
        setModRetrievalFailure(true)
    };

    useEffect(() => {
        setIDWasInURL(!!id)

        if (id) {
            if (window.location.href.includes('/test/book/id')) {
                // A Link to a Book Test has been entered
                if (isMobile) {
                    setShowNotAvailableInMobileMsg(true)
                    return
                }

                getBook(
                    {
                        bookId: id
                    }
                );
            } else {
                // A Link to a Spelling Test has been entered
                if (isMobile) {
                    setShowNotAvailableInMobileMsg(true)
                    return
                }

                setModRetrievalFailure(false)
                getMod(id, modRetrieved, retrievalFailure)
            }
        }
    }, []);

    useEffect(() => {
        if (bookFulfilledTimeStamp) {
            try {
                const bookDataInstance: z.infer<typeof IBookModSchema> = {
                    ...bookData?.data!,
                    _id: ObjectID(bookData!.data!._id!.toString())
                };

                appDispatch(setAssessmentPageTitle(bookDataInstance.title))
                appDispatch(setAssessmentPageType(bookDataInstance.type))
                appDispatch(setAssessmentCompletedBeforeStudying(true))
                appDispatch(setCurrentBook(bookDataInstance))
            } catch (e) {
                setModRetrievalFailure(true)
            }
        }
    }, [bookFulfilledTimeStamp]);

    useEffect(() => {
        if (getBookError) {
            setModRetrievalFailure(true)
        }
    }, [getBookError]);

    useEffect(() => {
        if (chapterFulfilledTimeStamp) {
            const chapterDataInstance: IBookModChapter = chapterData?.data!;

            appDispatch(saveSelectedChapterInfo({
                chapterID: selectedChapterID,
                title: chapterDataInstance.title,
                sections: chapterDataInstance.sections,
                assessmentCompletedBeforeStudying: true,
                saveSectionsAsInitialSelections: true
            }))

            appDispatch(resetChapterAssessment())
            setNavigationPathToHomeIfNeeded(IDWasInURL)
            appDispatch(update(Page.ANSWER_QUESTION))

            const chapterTestToStart: z.infer<typeof BaseIModRecordSchema> = {
                _id: ObjectID(newObjectId()),
                mod_id: ObjectID(selectedChapterID),
                username: userName
            }

            startChapterTest(chapterTestToStart,
                (newChapterTestRecordID) => {
                    appDispatch(saveChapterTestID(newChapterTestRecordID))
                },
                (error: any) => console.error("Error:", error)
            )
        }
    }, [chapterFulfilledTimeStamp]);

    const selectAllSections = (chapterID: string) => {
        const newSelectedSections: string[] = []

        currentBook.chapters?.find(chapter => chapter._id === chapterID)?.sections.forEach((section) => {
            newSelectedSections.push(section._id);
        });

        setSelectedSections(newSelectedSections);
    };

    const handleChapterRadioButtonClick = (chapterID: string) => {
        if (selectedChapterID !== chapterID) {
            setSelectedChapterID(chapterID)
            selectAllSections(chapterID)
        }
    };

    const handleSectionListItemClick = (sectionID: string) => {
        if (selectedSections.includes(sectionID)) {
            setSelectedSections([...selectedSections.filter(selectedSection => selectedSection !== sectionID)])
        } else {
            setSelectedSections([...selectedSections, sectionID])
        }
    };

    const handleGo_Click = () => {
        if (userName.trim() === '') {
            setSnackBarMsg('Name is required.')
            setShowSnackbarMsg(true)
            appDispatch(setValueOfSetFocusOnUsername(true))
            return
        }

        if (type === ModTypes.spelling) {
            setNavigationPathToHomeIfNeeded(IDWasInURL)
            appDispatch(resetSpellingAssessment())
            appDispatch(update(Page.SHOW_WORD))

            const spellingTestToStart: z.infer<typeof BaseIModRecordSchema> = {
                _id: ObjectID(newObjectId()),
                mod_id: ObjectID(spellingID),
                username: userName
            }

            startSpellingTest(spellingTestToStart,
                (newSpellingTestRecordID) => {
                    appDispatch(saveSpellingTestID(newSpellingTestRecordID))
                },
                (error: any) => console.error("Error:", error)
            )
        } else if (type === ModTypes.book) {
            if (selectedChapterID === '') {
                setSnackBarMsg('You must select a Chapter to Begin the Test')
                setShowSnackbarMsg(true)
                return
            }

            if (selectedSections.length === 0) {
                setSnackBarMsg('You must select at least one Section to begin the Test')
                setShowSnackbarMsg(true)
                return
            }

            appDispatch(setCurrentSectionID(selectedSections.sort()[0]))
            getChapter(
                {
                    bookID: currentBook._id!.toString(),
                    chapterID: selectedChapterID,
                    sectionIDs: selectedSections
                }
            );
        }

        appDispatch(saveUserName(userName.trim()))
    }

    return (
        <>
            {
                (() => {
                    if (isMobile) return <Header/>
                    if (IDWasInURL && modRetrievalFailure) return (
                        <ModOrPageNotFound notFoundType={NotFoundType.Mod}/>
                    )

                    return (
                        <div style={{height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                            <Sheet
                                sx={{
                                    background: "linear-gradient(to right, #48BB78, #3B82F6)",
                                    display: "flex",
                                    flexDirection: "column",
                                    overflowY: "auto",
                                    height: '100vh'
                                }}
                            >
                                <Title title={title}/>
                                <Description description={`Initial Assessment - ${formatDate(new Date())}`}/>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto'}}>
                                    <Body boxGap={2}>
                                        {
                                            (() => {
                                                if (type === ModTypes.book) return (
                                                    <LocalSettings
                                                        direction={LocalSettingsUI_Direction.row}/>
                                                )
                                            })()
                                        }
                                        <Typography
                                            level="h2"
                                            fontSize="x-large"
                                            fontWeight="lg"
                                            sx={{
                                                color: "white",
                                                borderWidth: "2px",
                                                borderColor: "white",
                                                display: type === ModTypes.book ? "none" : "block"
                                            }}
                                            variant="outlined"
                                            padding="15px"
                                            borderRadius="md"
                                        >
                                            {
                                                (() => {
                                                    if (type === ModTypes.book) return (
                                                        "Try to answer the questions correctly and quickly."
                                                    )

                                                    if (type === ModTypes.spelling) return (
                                                        "Try to spell the words correctly and quickly."
                                                    )
                                                })()
                                            }
                                        </Typography>
                                        {
                                            (() => {
                                                if (type === ModTypes.spelling) return (
                                                    <LocalSettings
                                                        direction={LocalSettingsUI_Direction.column}/>
                                                )
                                            })()
                                        }
                                        {
                                            (() => {
                                                if (type === ModTypes.book) {
                                                    return (
                                                        <>
                                                            <div style={{display: 'flex', gap: '25px'}}>
                                                                <Sheet
                                                                    variant="outlined"
                                                                    sx={{
                                                                        boxShadow: 'sm',
                                                                        borderRadius: 'sm',
                                                                        p: 2,
                                                                        width: '450px',
                                                                        height: '250px'
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        level="h4"
                                                                        paddingBottom="15px"
                                                                    >
                                                                        Choose the Chapter for Your Test
                                                                    </Typography>
                                                                    <RadioGroup
                                                                        size="lg"
                                                                        orientation="vertical"
                                                                        value={selectedChapterID}
                                                                        name="radio-buttons-group"
                                                                        sx={{
                                                                            height: 210,
                                                                            overflowY: 'auto',
                                                                            overflowX: 'hidden'
                                                                        }}>
                                                                        {currentBook.chapters.map((chapter) => {
                                                                            return (
                                                                                <Radio value={chapter._id}
                                                                                       label={chapter.title}
                                                                                       key={chapter._id}
                                                                                       sx={{
                                                                                           '& .MuiRadio-label': {
                                                                                               overflow: 'hidden',
                                                                                               textOverflow: 'ellipsis',
                                                                                               whiteSpace: 'nowrap',
                                                                                               maxWidth: '380px'
                                                                                           }
                                                                                       }}
                                                                                       onClick={() => handleChapterRadioButtonClick(chapter._id)}
                                                                                />
                                                                            )
                                                                        })}
                                                                    </RadioGroup>
                                                                </Sheet>
                                                                <Sheet
                                                                    variant="outlined"
                                                                    sx={{
                                                                        boxShadow: 'sm',
                                                                        borderRadius: 'sm',
                                                                        p: 2,
                                                                        width: '450px',
                                                                        height: '250px'
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        {
                                                                            (() => {
                                                                                if (selectedSections.length === currentBook.chapters?.find(chapter => chapter._id === selectedChapterID)?.sections?.length) {
                                                                                    return (
                                                                                        <CheckBox
                                                                                            sx={{
                                                                                                paddingLeft: '13px',
                                                                                                paddingRight: '14px',
                                                                                                color: '#0b6bcb',
                                                                                                cursor: 'pointer'
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                setSelectedSections([])
                                                                                            }}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                return (
                                                                                    <CheckBoxOutlineBlank
                                                                                        sx={{
                                                                                            paddingLeft: '13px',
                                                                                            paddingRight: '14px',
                                                                                            color: '#0b6bcb',
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            selectAllSections(selectedChapterID)
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            })()
                                                                        }
                                                                        <Typography
                                                                            level="h4"
                                                                        >
                                                                            Choose the Sections for Your Test
                                                                        </Typography>
                                                                    </Box>
                                                                    <List
                                                                        sx={{
                                                                            maxHeight: 210,
                                                                            overflow: 'auto'
                                                                        }}
                                                                    >
                                                                        {currentBook.chapters?.find(chapter => chapter._id === selectedChapterID)?.sections.map((section) => (
                                                                            <ListItem key={section._id}
                                                                            >
                                                                                <ListItemButton
                                                                                    selected={selectedSections.includes(section._id)}
                                                                                    onClick={() => handleSectionListItemClick(section._id)}
                                                                                >
                                                                                    <ListItemDecorator
                                                                                        sx={{color: '#0b6bcb'}}>
                                                                                        {selectedSections.includes(section._id) ?
                                                                                            <CheckBox/> :
                                                                                            <CheckBoxOutlineBlank/>
                                                                                        }
                                                                                    </ListItemDecorator>
                                                                                    <div style={{
                                                                                        overflow: 'hidden',
                                                                                        textOverflow: 'ellipsis',
                                                                                        whiteSpace: 'nowrap',
                                                                                        maxWidth: '380px',
                                                                                        fontFamily: 'Arial',
                                                                                        fontSize: '17px'
                                                                                    }}>
                                                                                        {section.title}
                                                                                    </div>
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        ))}
                                                                    </List>
                                                                </Sheet>
                                                            </div>
                                                            {
                                                                (() => {
                                                                    if (currentBook.notes) {
                                                                        return (
                                                                            <Card variant="soft"
                                                                                  sx={{
                                                                                      width: '50%',
                                                                                      maxHeight: 200
                                                                                  }}
                                                                            >
                                                                                <Typography
                                                                                    level="h4"
                                                                                >
                                                                                    Book Notes
                                                                                </Typography>
                                                                                <Box
                                                                                    sx={{
                                                                                        overflow: 'auto',
                                                                                        paddingRight: '20px'
                                                                                    }}
                                                                                    dangerouslySetInnerHTML={{__html: currentBook.notes}}
                                                                                />
                                                                            </Card>
                                                                        )
                                                                    }
                                                                })()
                                                            }
                                                        </>
                                                    )
                                                }
                                            })()
                                        }
                                    </Body>
                                </div>
                                <ButtonRow>
                                    <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                        <Button
                                            sx={resizeButtonSX()}
                                            onClick={
                                                () => {
                                                    setNavigationPathToHomeIfNeeded(IDWasInURL);
                                                    appDispatch(update(Page.HOME))
                                                }
                                            }
                                        >Cancel</Button>
                                        <Button variant="solid" color="primary"
                                                sx={resizeButtonSX()}
                                                onClick={handleGo_Click}>Go</Button>
                                    </ButtonGroup>
                                </ButtonRow>
                            </Sheet>
                        </div>
                    )
                })()
            }
            {
                (() => {
                    if (showSnackbarMsg)
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color={"warning"}
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                autoHideDuration={3000}
                                open={true}
                                onClose={() => {
                                    setShowSnackbarMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {snackBarMsg}
                            </Snackbar>
                        )
                })()
            }
            {
                (() => {
                    if (showNotAvailableInMobileMsg) {
                        return (
                            <MobileAccessNotSupported onClose={() => {
                                setShowNotAvailableInMobileMsg(false);
                                setNavigationPathToHomeIfNeeded(IDWasInURL)
                                appDispatch(update(Page.HOME))
                            }}
                            />
                        )
                    }
                })()
            }
        </>
    )
}