import React, {ChangeEvent, FC, useEffect, useState} from "react";
import {Header} from "src/components/Header";
import Typography from "@mui/joy/Typography";
import {Box, Radio, RadioGroup, Sheet, Snackbar, Tooltip} from "@mui/joy";
import {SpellingResult, useSpellingMgr} from "src/hooks/useSpellingMgr";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import {Page, setAssessmentPageTitle, setAssessmentPageType, update} from "src/slices/pageSlice";
import {ISpellingAssessment, resetSpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {ActivityFooter} from "src/components/Spelling/ActivityFooter";
import {saveMisspelledAndSkippedWordsInfo} from "src/slices/wordsSlice";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Title from "src/components/Shared/Title";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {
    CurrentAudioComponent,
    resetCorrectAudioOnEnd, resetWrongAudioOnEnd,
    setCurrentAudioComponent
} from 'src/slices/sharedSlice';
import shuffle from "lodash/shuffle";
import {ModTypes} from "src/utils/constants";
import {completeSpellingStudy} from "src/utils/api-service";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import GreenTrafficLight from "src/images/green_traffic_light.png"
import YellowTrafficLight from "src/images/yellow_traffic_light.png"
import RedTrafficLight from "src/images/red_traffic_light.png"

export const ChooseCorrectSpelling: FC = () => {
    const [currentWordIndex, setCurrentWordIndex] = useState<number>(-1);
    const results = useAppSelector((state: RootState) => state.spellingAssessment.data);
    const [selectedValue, setSelectedValue] = useState<string>('');
    const [choicesToDisplay, setChoicesToDisplay] = useState<string[]>([]);
    const [showWrongSelectionMsg, setShowWrongSelectionMsg] = useState<boolean>(false);
    const [currentTrafficLight, setCurrentTrafficLight] = useState(DarkTrafficLight)

    const appDispatch = useAppDispatch();

    const assessmentCompletedBeforeStudying = useAppSelector((state: RootState) => state.words.assessmentCompletedBeforeStudying)

    const title = useAppSelector((state: RootState) => state.words.title)
    const spellingStudyID = useAppSelector((state: RootState) => state.words.spellingStudyID);

    const {playCorrectAudio, playWrongAudio, speak} = useSharedMgr();
    const {getSpellingResult} = useSpellingMgr();

    const [wordsToStudy, setWordsToStudy] = useState<ISpellingAssessment[]>([]);

    const currentAudioComponent = useAppSelector((state: RootState) => state.shared?.currentAudioComponent);
    const correctAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.correctAudioOnEndFired);
    const wrongAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.wrongAudioOnEndFired);

    const wordsFromDB = useAppSelector((state: RootState) => state.words.words);

    useEffect(() => {
        appDispatch(setCurrentAudioComponent(CurrentAudioComponent.CHOOSE_CORRECT_SPELLING))

        if (assessmentCompletedBeforeStudying) {
            setWordsToStudy(results.filter(result => [SpellingResult.MISSPELLED, SpellingResult.SKIPPED].includes(getSpellingResult(result))).map(result => result))
        } else {
            setWordsToStudy(shuffle(wordsFromDB.map(item => ({
                _id: item._id,
                word: item.word,
                duration: 0
            }))))
        }
    }, []);

    useEffect(() => {
        if (correctAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.CHOOSE_CORRECT_SPELLING) {
            setSelectedValue('')
            setCurrentWordIndex(currentWordIndex + 1)
            setCurrentTrafficLight(DarkTrafficLight)
            appDispatch(resetCorrectAudioOnEnd())
        }
    }, [correctAudioOnEndFired])

    useEffect(() => {
        if (wrongAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.CHOOSE_CORRECT_SPELLING) {
            setCurrentTrafficLight(DarkTrafficLight)
            appDispatch(resetWrongAudioOnEnd())
        }
    }, [wrongAudioOnEndFired])

    useEffect(() => {
        if (currentWordIndex === -1) {
            return
        }

        if (currentWordIndex < wordsToStudy.length) {
            let wordInfo = wordsFromDB.find(word => word._id === wordsToStudy[currentWordIndex]._id)!
            let choicesToDisplay = [wordInfo.word, ...wordInfo.misspellings].sort(() => Math.random() - 0.5);

            setChoicesToDisplay(choicesToDisplay)
            speak(wordInfo.word)
        }
    }, [currentWordIndex])

    const handleStart_Click = () => {
        setCurrentWordIndex(0)
    }

    const handleSubmit_Click = async () => {
        if (selectedValue === wordsFromDB.find(word => word._id === wordsToStudy[currentWordIndex]._id)!.word) {
            setCurrentTrafficLight(GreenTrafficLight)
            playCorrectAudio()
        } else {
            if (selectedValue === '') {
                setCurrentTrafficLight(YellowTrafficLight)
            } else {
                setCurrentTrafficLight(RedTrafficLight)
            }

            playWrongAudio()
            setShowWrongSelectionMsg(true)
        }
    }

    const handlePlayWord_Click = () => {
        let wordInfo = wordsFromDB.find(word => word._id === wordsToStudy[currentWordIndex]._id)!

        speak(wordInfo.word)
    }

    const handleRedoMisspelledAndSkippedWordsClick = () => {
        const matchingMisspelledAndSkippedWordsToStudy = wordsFromDB.filter(item2 => wordsToStudy.some(item1 => item1._id === item2._id));

        appDispatch(resetSpellingAssessment())

        // This is needed so that only the misspelled and skipped words are shown for studying rather than all words.
        appDispatch(saveMisspelledAndSkippedWordsInfo({
            words: matchingMisspelledAndSkippedWordsToStudy
        }))
        appDispatch(update(Page.SHOW_WORD))
    }

    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            <Header/>
            <Title title={'Choose the Correct Spelling'}/>
            <Body>
                {
                    (() => {
                            if (currentWordIndex < wordsToStudy.length) {
                                return (
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: 20
                                        }}>
                                        <RadioGroup
                                            size="lg"
                                            value={selectedValue}
                                            name="radio-buttons-group"
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                setSelectedValue(event.target.value)
                                            }}
                                            sx={{
                                                gap: 1
                                            }}>
                                            {choicesToDisplay.map((choice, index) => (
                                                <Sheet
                                                    component="label"
                                                    key={choice}
                                                    variant="outlined"
                                                    sx={{
                                                        p: 2,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'left',
                                                        boxShadow: 'sm',
                                                        borderRadius: 'lg',
                                                        border: '1px solid',
                                                        borderColor: 'primary.500'
                                                    }}
                                                >
                                                    <Radio key={index} value={choice} label={choice}/>
                                                </Sheet>
                                            ))}
                                        </RadioGroup>
                                        <br/>
                                        {
                                            (() => {
                                                if (choicesToDisplay.length > 0) {
                                                    return (
                                                        <Box
                                                            component="img"
                                                            sx={{
                                                                height: 125
                                                            }}
                                                            alt={
                                                                'Traffic Light'
                                                            }
                                                            src={
                                                                currentTrafficLight
                                                            }
                                                        />
                                                    )
                                                }
                                            })()
                                        }
                                    </Box>
                                )
                            }

                            {
                                completeSpellingStudy(spellingStudyID,
                                    (response) => {
                                        if (response.data.message === 'Spelling Study Record Not Found') {
                                            console.log(response.data.message)
                                        }
                                    },
                                    (error: any) => console.error("Error:", error)
                                )

                                return (
                                    <Typography fontWeight="lg" fontSize="xx-large">
                                        All Done
                                    </Typography>
                                )
                            }
                        }
                    )()
                }
            </Body>
            <ButtonRow>
                {
                    (() => {
                        if (currentWordIndex === -1) {
                            return (
                                <Button variant="solid" color="primary"
                                        sx={resizeButtonSX()}
                                        onClick={handleStart_Click}>Start</Button>
                            )
                        }

                        if (currentWordIndex < wordsToStudy.length) {
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Button sx={resizeButtonSX()}
                                            onClick={handlePlayWord_Click}>Play Word</Button>
                                    <Button variant="solid" color="primary"
                                            sx={resizeButtonSX()}
                                            onClick={handleSubmit_Click}>Submit</Button>
                                </ButtonGroup>
                            )
                        }

                        if (assessmentCompletedBeforeStudying) {
                            // "Start" was clicked on Home Page
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Tooltip title="Do the Whole Assessment Again" variant="solid">
                                        <Button
                                            sx={resizeButtonSX()}
                                            color="primary" onClick={() => {
                                            appDispatch(resetSpellingAssessment())
                                            appDispatch(update(Page.SHOW_WORD))
                                        }}>Restart</Button>
                                    </Tooltip>
                                    <Tooltip title="Do Only Misspelled and Skipped Words Again" variant="solid">
                                        <Button
                                            sx={resizeButtonSX()}
                                            variant="solid" color="primary"
                                            onClick={handleRedoMisspelledAndSkippedWordsClick}>Retry
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Go to the Home Page" variant="solid">
                                        <Button
                                            sx={resizeButtonSX()}
                                            color="primary" onClick={() =>
                                            appDispatch(update(Page.HOME))
                                        }>Home</Button>
                                    </Tooltip>
                                </ButtonGroup>
                            )
                        } else {
                            // "Study" was clicked on Home Page
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Tooltip title="Redo the Study Session" variant="solid">
                                        <Button sx={resizeButtonSX()}
                                                onClick={
                                                    () => {
                                                        appDispatch(update(Page.STUDY_WORDS))
                                                    }
                                                }>Study Again</Button>
                                    </Tooltip>
                                    <Tooltip title="Go to the Home Page">
                                        <Button sx={resizeButtonSX()} variant="solid" color="primary" onClick={() =>
                                            appDispatch(update(Page.HOME))
                                        }>Home</Button>
                                    </Tooltip>
                                    <Tooltip title="Start Spelling Assessment">
                                        <Button sx={resizeButtonSX()}
                                                onClick={() => {
                                                    appDispatch(update(Page.ASSESSMENT))
                                                    appDispatch(setAssessmentPageTitle(title))
                                                    appDispatch(setAssessmentPageType(ModTypes.spelling))
                                                }
                                                }>Start</Button>
                                    </Tooltip>
                                </ButtonGroup>
                            )
                        }
                    })()
                }
            </ButtonRow>
            <ActivityFooter currentIndex={currentWordIndex} totalCount={wordsToStudy.length}/>
            <Snackbar
                size="lg"
                variant="solid"
                color="warning"
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                autoHideDuration={1750}
                open={showWrongSelectionMsg}
                onClose={() => {
                    setShowWrongSelectionMsg(false);
                }}
                sx={{
                    justifyContent: 'center',
                    padding: '5px',
                }}
            >
                {
                    selectedValue === '' ? 'Please make a selection.' : "That's not correct. Please try again."
                }
            </Snackbar>
        </div>
    )
}