import {z} from "zod";
import {
    IBookModStudyRecordBookSchema,
    IBookModStudyRecordSchema,
    IBookModTestRecordSchema
} from "src/zodSchemas";
import {formatDate, newObjectId} from "src/utils/functions";
import ObjectID from "bson-objectid";

const getCaseInsensitiveItem = (key: string): string | null => {
    const matchingKey = Object.keys(localStorage).find(
        storedKey => storedKey.toLowerCase() === key.toLowerCase()
    );
    return matchingKey ? localStorage.getItem(matchingKey) : null;
};

export const startSectionStudy = (
    user: string,
    bookID: string,
    bookTitle: string,
    chapterID: string,
    chapterTitle: string,
    sectionID: string,
    sectionTitle: string
) => {
    const formattedDate = formatDate(new Date());
    const dayKey = `bookStudyRecord/${formattedDate}/${user}`;

    // Define the structure for the book being studied
    const bookBeingStudied: z.infer<typeof IBookModStudyRecordBookSchema> = {
        _id: bookID,
        chapters: [
            {
                _id: chapterID,
                sections: [
                    {
                        _id: sectionID,
                        title: sectionTitle,
                    },
                ],
                title: chapterTitle,
            },
        ],
        title: bookTitle,
    };

    // Retrieve existing data for the day
    const existingDataString = getCaseInsensitiveItem(dayKey);

    if (existingDataString) {
        // Parse the existing data
        const existingData: z.infer<typeof IBookModStudyRecordSchema> = JSON.parse(existingDataString);

        // Check if the book already exists in the day's record
        const bookIndex = existingData.books.findIndex((book) => book._id === bookBeingStudied._id);

        if (bookIndex === -1) {
            // Add the new book if it doesn't exist
            existingData.books.push(bookBeingStudied);
        } else {
            // If the book exists, check for the chapter
            const existingBook = existingData.books[bookIndex];
            const chapterIndex = existingBook.chapters.findIndex((chapter) => chapter._id === chapterID);

            if (chapterIndex === -1) {
                // Add a new chapter if it doesn't exist
                existingBook.chapters.push(bookBeingStudied.chapters[0]);
            } else {
                // If the chapter exists, check for the section
                const existingChapter = existingBook.chapters[chapterIndex];
                const sectionIndex = existingChapter.sections.findIndex((section) => section._id === sectionID);

                if (sectionIndex === -1) {
                    // Add the section if it doesn't exist
                    existingChapter.sections.push(bookBeingStudied.chapters[0].sections[0]);
                }
            }
        }

        // Save the updated day's data back to localStorage
        localStorage.setItem(dayKey, JSON.stringify(existingData));
    } else {
        // Create a new day's entry if none exists
        const newDayData = {
            _id: new ObjectID(newObjectId().toString()),
            user: user,
            added_on: formattedDate,
            books: [bookBeingStudied],
        };

        // Save the new day's data to localStorage
        localStorage.setItem(dayKey, JSON.stringify(newDayData));
    }
}

export const completeSectionStudy = (
    user: string,
    bookID: string,
    bookTitle: string,
    chapterID: string,
    chapterTitle: string,
    sectionID: string,
    sectionTitle: string
) => {
    const formattedDate = formatDate(new Date());
    const dayKey = `bookStudyRecord/${formattedDate}/${user}`;

    // Define the section structure
    const completedSection = {
        _id: sectionID,
        title: sectionTitle,
        complete: true,
    };

    // Retrieve existing data for the day
    const existingDataString = getCaseInsensitiveItem(dayKey);

    if (existingDataString) {
        // Parse the existing data
        const existingData: z.infer<typeof IBookModStudyRecordSchema> = JSON.parse(existingDataString);

        // Check if the book already exists in the day's record
        let book = existingData.books.find((book) => book._id === bookID);

        if (!book) {
            // Create the book if it doesn't exist
            book = {
                _id: bookID,
                title: bookTitle,
                chapters: [],
            };
            existingData.books.push(book);
        }

        // Check if the chapter already exists in the book
        let chapter = book.chapters.find((chapter) => chapter._id === chapterID);

        if (!chapter) {
            // Create the chapter if it doesn't exist
            chapter = {
                _id: chapterID,
                title: chapterTitle,
                sections: [],
            };
            book.chapters.push(chapter);
        }

        // Check if the section already exists in the chapter
        let section = chapter.sections.find((section) => section._id === sectionID);

        if (!section) {
            // Create the section if it doesn't exist
            section = completedSection;
            chapter.sections.push(section);
        } else {
            // If the section exists, mark it as complete
            section.complete = true;
        }

        // Save the updated day's data back to localStorage
        localStorage.setItem(dayKey, JSON.stringify(existingData));
    } else {
        // Create a new day's entry if none exists
        const newDayData = {
            _id: new ObjectID(newObjectId().toString()),
            user: user,
            added_on: formattedDate,
            books: [
                {
                    _id: bookID,
                    title: bookTitle,
                    chapters: [
                        {
                            _id: chapterID,
                            title: chapterTitle,
                            sections: [completedSection],
                        },
                    ],
                },
            ],
        };

        // Save the new day's data to localStorage
        localStorage.setItem(dayKey, JSON.stringify(newDayData));
    }
}

export const startSectionTest = (
    user: string,
    bookID: string,
    bookTitle: string,
    chapterID: string,
    chapterTitle: string,
    sectionID: string,
    sectionTitle: string
) => {
    const formattedDate = formatDate(new Date());
    const dayKey = `bookTestRecord/${formattedDate}/${user}`;

    // Retrieve existing data for the day
    const existingDataString = getCaseInsensitiveItem(dayKey);

    const newSection = {
        _id: sectionID,
        title: sectionTitle,
        percent_correct: 0,
        percent_incorrect: 0,
        percent_skipped: 0,
        percent_typo: 0,
        counter: 0
    };

    if (existingDataString) {
        const existingData: z.infer<typeof IBookModTestRecordSchema> = JSON.parse(existingDataString);

        // Ensure book exists
        let book = existingData.books.find(b => b._id === bookID);
        if (!book) {
            book = {
                _id: bookID,
                title: bookTitle,
                percent_correct: 0,
                percent_incorrect: 0,
                percent_skipped: 0,
                percent_typo: 0,
                chapters: [],
                counter: 0
            };
            existingData.books.push(book);
        }

        // Ensure chapter exists
        let chapter = book.chapters.find(c => c._id === chapterID);
        if (!chapter) {
            chapter = {
                _id: chapterID,
                title: chapterTitle,
                percent_correct: 0,
                percent_incorrect: 0,
                percent_skipped: 0,
                percent_typo: 0,
                sections: [],
                counter: 0
            };
            book.chapters.push(chapter);
        }

        // Ensure section exists
        if (!chapter.sections.some(s => s._id === sectionID)) {
            chapter.sections.push(newSection);
        }

        localStorage.setItem(dayKey, JSON.stringify(existingData));
    } else {
        // Initialize new record
        const newData = {
            user,
            added_on: formattedDate,
            books: [
                {
                    _id: bookID,
                    title: bookTitle,
                    percent_correct: 0,
                    percent_incorrect: 0,
                    percent_skipped: 0,
                    percent_typo: 0,
                    counter: 0,
                    chapters: [
                        {
                            _id: chapterID,
                            title: chapterTitle,
                            percent_correct: 0,
                            percent_incorrect: 0,
                            percent_skipped: 0,
                            percent_typo: 0,
                            counter: 0,
                            sections: [newSection],
                        },
                    ],
                },
            ],
        };
        localStorage.setItem(dayKey, JSON.stringify(newData));
    }
};
export const completeSectionTest = (
    user: string,
    bookID: string,
    chapterID: string,
    sectionID: string,
    results: { percent_correct: number; percent_incorrect: number; percent_skipped: number; percent_typo: number }
): { dayKey: string; updatedData: string } | null => {
    const dayKey = `bookTestRecord/${formatDate(new Date())}/${user}`;
    const existingDataString = getCaseInsensitiveItem(dayKey);

    if (existingDataString) {
        const existingData: z.infer<typeof IBookModTestRecordSchema> = JSON.parse(existingDataString);

        // Find the book
        const book = existingData.books.find(b => b._id === bookID);
        if (!book) return null;

        // Find the chapter
        const chapter = book.chapters.find(c => c._id === chapterID);
        if (!chapter) return null;

        // Find the section
        const section = chapter.sections.find(s => s._id === sectionID);
        if (!section) return null;

        // Update section percentages and increment counter
        section.counter += 1;
        section.percent_correct = ((section.percent_correct * (section.counter - 1)) + results.percent_correct) / section.counter;
        section.percent_incorrect = ((section.percent_incorrect * (section.counter - 1)) + results.percent_incorrect) / section.counter;
        section.percent_skipped = ((section.percent_skipped * (section.counter - 1)) + results.percent_skipped) / section.counter;
        section.percent_typo = ((section.percent_typo * (section.counter - 1)) + results.percent_typo) / section.counter;

        // Update chapter cumulative percentages and increment counter
        chapter.counter += 1;
        chapter.percent_correct =
            chapter.sections.reduce((sum, sec) => sum + (sec.percent_correct * sec.counter), 0) /
            chapter.sections.reduce((sum, sec) => sum + sec.counter, 0);
        chapter.percent_incorrect =
            chapter.sections.reduce((sum, sec) => sum + (sec.percent_incorrect * sec.counter), 0) /
            chapter.sections.reduce((sum, sec) => sum + sec.counter, 0);
        chapter.percent_skipped =
            chapter.sections.reduce((sum, sec) => sum + (sec.percent_skipped * sec.counter), 0) /
            chapter.sections.reduce((sum, sec) => sum + sec.counter, 0);
        chapter.percent_typo =
            chapter.sections.reduce((sum, sec) => sum + (sec.percent_typo * sec.counter), 0) /
            chapter.sections.reduce((sum, sec) => sum + sec.counter, 0);

        // Update book cumulative percentages and increment counter
        book.counter += 1;
        book.percent_correct =
            book.chapters.reduce((sum, ch) => sum + (ch.percent_correct * ch.counter), 0) /
            book.chapters.reduce((sum, ch) => sum + ch.counter, 0);
        book.percent_incorrect =
            book.chapters.reduce((sum, ch) => sum + (ch.percent_incorrect * ch.counter), 0) /
            book.chapters.reduce((sum, ch) => sum + ch.counter, 0);
        book.percent_skipped =
            book.chapters.reduce((sum, ch) => sum + (ch.percent_skipped * ch.counter), 0) /
            book.chapters.reduce((sum, ch) => sum + ch.counter, 0);
        book.percent_typo =
            book.chapters.reduce((sum, ch) => sum + (ch.percent_typo * ch.counter), 0) /
            book.chapters.reduce((sum, ch) => sum + ch.counter, 0);

        const updatedData = JSON.stringify(existingData);

        return {dayKey, updatedData};
    } else {
        console.log("No test data found for today");
        return null;
    }
};