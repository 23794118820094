import React, {FC, useEffect, useRef, useState} from 'react';
import {Header} from "src/components/Header";
import {Box, Button, Input, Modal, ModalClose, Sheet, Snackbar} from "@mui/joy";
import {Footer} from "src/components/Footer";
import {
    resetCurrentQuestionIndex, setCurrentSectionID,
    setCurrentTrafficLight, setExtractedQsandAs,
    setQuestionHasBeenRead,
    updateAnswer
} from "src/slices/chapterSlice";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {useChapterMgr} from "src/hooks/useChapterMgr";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Typography from "@mui/joy/Typography";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {resizeButtonSX} from "src/utils/ui";
import Title from "src/components/Shared/Title";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import {
    CurrentAudioComponent,
    resetCorrectAudioOnEnd,
    resetWrongAudioOnEnd,
    setCurrentAudioComponent
} from "src/slices/sharedSlice";
import DarkTrafficLight from "src/images/dark_traffic_light.png"
import {Page, update} from "src/slices/pageSlice";
import Description from "src/components/Shared/Description";
import {ChapterResult} from 'src/utils/constants';
import {startSectionTest} from "src/utils/book-records";

export const AnswerQuestion: FC = () => {
    const [showTypeAnswerMsg, setShowTypeAnswerMsg] = useState(false);
    const [questionHasBeenSkipped, setQuestionHasBeenSkipped] = useState(false);
    const [buttonsShouldBeDisabled, setButtonsShouldBeDisabled] = useState(false)
    const [showCorrectAnswerPopup, setShowCorrectAnswerPopup] = useState(false)
    const [wrongUserAnswer, setWrongUserAnswer] = useState('')

    const refInput = useRef<HTMLInputElement>(null);

    const currentQuestionIndex = useAppSelector((state: RootState) => state.chapter.currentQuestionIndex);
    const extractedQsandAs = useAppSelector((state: RootState) => state.chapter.extractedQsandAs);
    const userAnswer = useAppSelector((state: RootState) => state.chapter.userAnswer);
    const submitWhenEnterKeyIsPressed = useAppSelector((state: RootState) => state.settings.submitWhenEnterKeyIsPressed);
    const sections = useAppSelector((state: RootState) => state.chapter.sections);
    const currentSectionID = useAppSelector((state: RootState) => state.chapter.currentSectionID);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);
    const chapterID = useAppSelector((state: RootState) => state.chapter.chapterID);
    const chapterTitle = useAppSelector((state: RootState) => state.chapter.title);

    const [currentQuestion, setCurrentQuestion] = useState('')

    const {submitAnswer} = useChapterMgr();
    const {playWrongAudio, speak, speakWithEndFlag, stopSpeaking} = useSharedMgr();

    const currentAudioComponent = useAppSelector((state: RootState) => state.shared?.currentAudioComponent);
    const correctAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.correctAudioOnEndFired);
    const wrongAudioOnEndFired = useAppSelector((state: RootState) => state.shared?.wrongAudioOnEndFired);
    const currentTrafficLight = useAppSelector((state: RootState) => state.chapter.currentTrafficLight);
    const correctAnswer = useAppSelector((state: RootState) => state.chapter.correctAnswer);
    const answerResult = useAppSelector((state: RootState) => state.chapter.answerResult);
    const userName = useAppSelector((state: RootState) => state.settings.userName);

    const appDispatch = useAppDispatch();

    useEffect(() => {
        appDispatch(setCurrentAudioComponent(CurrentAudioComponent.ANSWER_QUESTION))
        startSectionTest(userName, currentBook._id!.toString(), currentBook.title, chapterID, chapterTitle, currentSectionID, sections[0].title)
    }, []);

    const checkForTestCompletion = () => {
        if (currentQuestionIndex > extractedQsandAs.length - 1) {
            appDispatch(resetCurrentQuestionIndex())

            // Check if questions for another section need to be answered.
            const indexOfCurrentSection = sections.findIndex(section => section._id === currentSectionID)

            if (indexOfCurrentSection < sections.length - 1) {
                const nextSection = sections.find(s => s._id === sections[indexOfCurrentSection + 1]._id)

                startSectionTest(userName, currentBook._id!.toString(), currentBook.title, chapterID, chapterTitle, nextSection!._id, nextSection!.title)
                appDispatch(setCurrentSectionID(nextSection!._id))
                appDispatch(setExtractedQsandAs())
            } else {
                appDispatch(update(Page.CHAPTER_RESULT))
            }
        } else {
            setupQuestion();
        }
    }

    useEffect(() => {
        if (correctAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.ANSWER_QUESTION) {
            appDispatch(resetCorrectAudioOnEnd())
            checkForTestCompletion()
        }
    }, [correctAudioOnEndFired])

    useEffect(() => {
        if (wrongAudioOnEndFired && currentAudioComponent === CurrentAudioComponent.ANSWER_QUESTION) {
            if (showTypeAnswerMsg) {
                setButtonsShouldBeDisabled(false)
                appDispatch(resetWrongAudioOnEnd())
                checkForTestCompletion()
            } else {
                if (userAnswer.length < correctAnswer.length) {
                    setWrongUserAnswer(userAnswer.padEnd(correctAnswer.length, ' '))
                } else {
                    setWrongUserAnswer(userAnswer)
                }

                refInput.current!.disabled = false
                refInput.current?.focus();
                setShowCorrectAnswerPopup(true)
            }
        }
    }, [wrongAudioOnEndFired])

    useEffect(() => {
        if (extractedQsandAs.length > 0 && currentQuestionIndex < extractedQsandAs.length) {
            setupQuestion();
        }
    }, [extractedQsandAs]);

    useEffect(() => {
        if (questionHasBeenSkipped) {
            doSubmission()
            setQuestionHasBeenSkipped(false)
        }
    }, [questionHasBeenSkipped, userAnswer]);

    const setupQuestion = () => {
        let currentQuestion = extractedQsandAs![currentQuestionIndex]?.question

        if (!currentQuestion)
            return

        setButtonsShouldBeDisabled(false)
        refInput.current!.disabled = false
        refInput.current?.focus();
        speak(currentQuestion)
        setCurrentQuestion(currentQuestion)
        appDispatch(updateAnswer(''));
        appDispatch(setQuestionHasBeenRead(true));
        appDispatch(setCurrentTrafficLight(DarkTrafficLight))
    }

    const doSubmission = () => {
        refInput.current!.disabled = true
        submitAnswer();
        stopSpeaking();
    }

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
                <Header/>
                <Title title={currentBook.title}/>
                <Description description={sections.find(section => section._id === currentSectionID)?.title || ''}/>
                <Body boxGap={7}>
                    <Typography fontSize="x-large">
                        {currentQuestion}
                    </Typography>
                    <Input
                        slotProps={{
                            input: {
                                ref: refInput
                            },
                        }}
                        value={userAnswer}
                        placeholder="Type Answer Here"
                        size="lg"
                        onKeyDown={
                            (event) => {
                                if (event.key === 'Enter' && userAnswer.trim() !== '' && submitWhenEnterKeyIsPressed === 'yes') {
                                    doSubmission()
                                }
                            }
                        }
                        onChange={(event) => appDispatch(updateAnswer(event.target.value))}
                        spellCheck="false"
                    />
                    <Box
                        component="img"
                        sx={{
                            height: 125
                        }}
                        alt={
                            'Traffic Light'
                        }
                        src={
                            currentTrafficLight
                        }
                    />
                </Body>
                <ButtonRow>
                    <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                        <Button sx={resizeButtonSX()}
                                disabled={buttonsShouldBeDisabled}
                                onClick={
                                    async () => {
                                        setButtonsShouldBeDisabled(true)
                                        await speakWithEndFlag(currentQuestion)
                                        setButtonsShouldBeDisabled(false)
                                        refInput.current?.focus();
                                    }
                                }
                        >
                            Read Sentence
                        </Button>
                        <Button sx={resizeButtonSX()} variant="solid" color="primary"
                                disabled={buttonsShouldBeDisabled}
                                onClick={
                                    () => {
                                        setButtonsShouldBeDisabled(true)

                                        if (userAnswer.trim() === '') {
                                            refInput.current!.disabled = true
                                            setShowTypeAnswerMsg(true)
                                            playWrongAudio()
                                            return
                                        }

                                        doSubmission()
                                    }
                                }>
                            Submit
                        </Button>
                        <Button sx={resizeButtonSX()}
                                disabled={buttonsShouldBeDisabled}
                                onClick={
                                    () => {
                                        setButtonsShouldBeDisabled(true)
                                        playWrongAudio()
                                        refInput.current!.disabled = true
                                        appDispatch(updateAnswer(''))
                                        setQuestionHasBeenSkipped(true)
                                    }
                                }>
                            Next
                        </Button>
                    </ButtonGroup>
                </ButtonRow>
                <Footer/>
                <Snackbar
                    size="lg"
                    variant="solid"
                    color="warning"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    autoHideDuration={1750}
                    open={showTypeAnswerMsg}
                    onClose={() => {
                        setShowTypeAnswerMsg(false);
                    }}
                    sx={{
                        justifyContent: 'center',
                        padding: '5px',
                    }}
                >
                    Please answer the question and then Submit.
                </Snackbar>
            </div>
            {
                (() => {
                    if (showCorrectAnswerPopup) {
                        const closeCorrectAnswerPopup = () => {
                            setShowCorrectAnswerPopup(false)
                            setButtonsShouldBeDisabled(false)
                            appDispatch(resetWrongAudioOnEnd())
                            checkForTestCompletion()
                        };

                        return (
                            <Modal
                                aria-labelledby="modal-title"
                                open={true}
                                onKeyDown={
                                    (event) => {
                                        if (event.key === 'Enter' || event.key === 'Escape') {
                                            closeCorrectAnswerPopup()
                                        }
                                    }
                                }
                                onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                                    if (reason === 'closeClick') {
                                        closeCorrectAnswerPopup();
                                    }
                                }}
                                sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 500}}
                            >
                                <Sheet
                                    variant="outlined"
                                    sx={{
                                        minWidth: 400,
                                        maxWidth: 600,
                                        borderRadius: 'md',
                                        p: 3,
                                        boxShadow: 'lg',
                                        overflow: 'auto',
                                        maxHeight: 500
                                    }}
                                >
                                    <ModalClose variant="plain" sx={{m: 1}}/>
                                    <Typography
                                        component="h2"
                                        id="modal-title"
                                        level="h4"
                                        textColor="inherit"
                                        fontWeight="lg"
                                        mb={1}
                                        maxWidth="500px"
                                    >
                                        {answerResult === ChapterResult.INCORRECT ? 'Your Answer was incorrect' :
                                            answerResult === ChapterResult.TYPO ? 'Your Answer has a Typo' :
                                                'You skipped entering an Answer'}<br/>
                                    </Typography>
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {
                                            answerResult === ChapterResult.INCORRECT || answerResult === ChapterResult.SKIPPED ?
                                                <p>The Correct Answer is: <strong>{correctAnswer}</strong></p> :
                                                // The answer had a typo.
                                                // Correct Answer
                                                <>
                                                    <Box sx={{width: '100%'}}>
                                                        <Typography
                                                            sx={{textDecoration: 'underline'}}
                                                        >
                                                            Correct Answer
                                                        </Typography>
                                                    </Box>
                                                    <Box style={{display: 'flex', flexDirection: 'row'}}>
                                                        <div style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            width: '100%',
                                                            maxWidth: '100%',
                                                            height: '75px',
                                                            maxHeight: '75px',
                                                            overflowY: 'scroll',
                                                            overflowX: 'hidden',
                                                            paddingRight: '5px',
                                                            paddingBottom: '5pxYou Skipped entering an Answer'
                                                        }}>

                                                            {
                                                                correctAnswer?.split('').map((letter, mapIndex) => (
                                                                    <Input id={`correct-letter-${mapIndex + 1}`}
                                                                           size='sm'
                                                                           value={letter}
                                                                           variant="outlined"
                                                                           key={mapIndex} disabled
                                                                           sx={{
                                                                               width: '30px',
                                                                               minWidth: '30px',
                                                                               paddingRight: '5px',
                                                                               height: '25px',
                                                                               maxHeight: '25px',
                                                                               background: 'green',
                                                                               "& input": {
                                                                                   color: 'white',
                                                                                   fontSize: '20px',
                                                                                   textAlign: "center"
                                                                               }
                                                                           }}
                                                                    />
                                                                ))
                                                            }
                                                        </div>
                                                    </Box>
                                                    {/*User Answer*/}
                                                    <br/>
                                                    <Box sx={{width: '100%', marginTop: '10px'}}>
                                                        <Typography
                                                            sx={{textDecoration: 'underline'}}
                                                        >
                                                            Your Answer
                                                        </Typography>
                                                    </Box>
                                                    <div style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        width: '100%',
                                                        maxWidth: '100%',
                                                        height: '75px',
                                                        maxHeight: '75px',
                                                        overflowY: 'scroll',
                                                        overflowX: 'hidden',
                                                        paddingRight: '5px',
                                                        paddingBottom: '5pxYou Skipped entering an Answer'
                                                    }}>
                                                        {
                                                            wrongUserAnswer?.split('').map((letter, mapIndex) => (
                                                                <Input
                                                                    id={`user-letter-${mapIndex + 1}`}
                                                                    size='sm'
                                                                    value={letter}
                                                                    variant="outlined"
                                                                    key={mapIndex}
                                                                    disabled
                                                                    sx={{
                                                                        width: '30px',
                                                                        minWidth: '30px',
                                                                        paddingRight: '5px',
                                                                        height: '25px',
                                                                        maxHeight: '25px',
                                                                        background: correctAnswer?.split('')[mapIndex]
                                                                            ? letter.toLowerCase() === correctAnswer.split('')[mapIndex].toLowerCase()
                                                                                ? 'green'
                                                                                : 'red'
                                                                            : 'red',
                                                                        "& input": {
                                                                            color: 'white',
                                                                            fontSize: '20px',
                                                                            textAlign: "center"
                                                                        }
                                                                    }}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                </>
                                        }
                                    </Box>
                                </Sheet>
                            </Modal>
                        )
                    }
                })()
            }
        </>
    );
}
