import React, {FC, ReactNode, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import Title from "src/components/Shared/Title";
import {Header} from "src/components/Header";
import Body from "src/components/Shared/Body";
import {BaseIModRecordSchema, IBookModChapterSectionSchema} from "src/zodSchemas";
import {z} from "zod";
import ButtonRow from "src/components/Shared/ButtonRow";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import ButtonGroup from "@mui/joy/ButtonGroup";
import {ActivityFooter} from "src/components/Spelling/ActivityFooter";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Description from "src/components/Shared/Description";
import parse, {Element} from "html-react-parser";
import {useChapterMgr} from "src/hooks/useChapterMgr";
import {Box, Chip, Sheet} from "@mui/joy";
import {CheckCircleOutlined, Pending} from "@mui/icons-material";
import {Page, update} from "src/slices/pageSlice";
import ObjectID from "bson-objectid";
import {newObjectId} from "src/utils/functions";
import {startChapterStudy} from "src/utils/api-service";
import {saveChapterStudyID} from "src/slices/chapterSlice";
import {startSectionStudy} from "src/utils/book-records";
import {ChapterResult} from 'src/utils/constants';

export const StudySections: FC = () => {
    const [paraCounter, setParaCounter] = useState(0)
    const [sectionCounter, setSectionCounter] = useState(0)
    const [currentSection, setCurrentSection] = useState<z.infer<typeof IBookModChapterSectionSchema> | null>(null);
    const [readingComplete, setReadingComplete] = useState(false)
    const [answersToShow, setAnswersToShow] = useState<string[]>([])

    const assessmentResults = useAppSelector((state: RootState) => state.chapterAssessment.data);
    const sections = useAppSelector((state: RootState) => state.chapter.sections);
    const chapterID = useAppSelector((state: RootState) => state.chapter.chapterID);
    const currentSectionID = useAppSelector((state: RootState) => state.chapter.currentSectionID);
    const chapterTitle = useAppSelector((state: RootState) => state.chapter.title);
    const assessmentCompletedBeforeStudying = useAppSelector((state: RootState) => state.chapter.assessmentCompletedBeforeStudying)
    const userName = useAppSelector((state: RootState) => state.settings.userName);
    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);

    const appDispatch = useAppDispatch();

    const {getChapterResult, highlightAnswersInParagraph} = useChapterMgr();

    const refTitle = useRef<string>('');
    const refDesc = useRef<string>('');
    const refIncorrectAndSkippedResults = useRef(assessmentResults.filter(result => [ChapterResult.INCORRECT, ChapterResult.SKIPPED].includes(getChapterResult(result))));

    const {speakWithEndFlag, stopSpeaking} = useSharedMgr();

    useEffect(() => {
        if (assessmentCompletedBeforeStudying) {
            setCurrentSection(sections[0])
            refTitle.current = sections[0].title
            refDesc.current = chapterTitle
            setAnswersToShow(Array.from(new Set(refIncorrectAndSkippedResults.current.map(obj => obj.correct_answer))))
        } else {
            // 'Study' has been clicked on a ModCard or a Link has been used to access this component.
            setCurrentSection(sections[0])
            refTitle.current = sections[0].title
            refDesc.current = chapterTitle
            setAnswersToShow(Array.from(new Set(sections?.flatMap(section =>
                section.paragraphs?.flatMap(paragraph =>
                    paragraph.questions_and_answers?.map(qa => qa.answer)
                )
            ))))
        }

        // Store the Start Study details in the DB
        const chapterStudyToStart: z.infer<typeof BaseIModRecordSchema> = {
            _id: ObjectID(newObjectId()),
            mod_id: ObjectID(chapterID),
            username: userName
        }

        startSectionStudy(userName, currentBook._id!.toString(), currentBook.title, chapterID, chapterTitle, currentSectionID, refTitle.current)
        startChapterStudy(chapterStudyToStart,
            (newChapterStudyRecordID: any) => {
                appDispatch(saveChapterStudyID(newChapterStudyRecordID))
            },
            (error: any) => console.error("Error:", error)
        )
    }, []);

    useEffect(() => {
        if (currentSection?.paragraphs?.length! === paraCounter) {
            // All Paragraphs in the Section have been viewed.
            setSectionCounter(prevState => prevState + 1)
            setParaCounter(0)
        }
    }, [paraCounter]);

    useEffect(() => {
        if (!currentSection)
            return

        if ((sections?.length!) === sectionCounter) {
            // All Sections have been viewed.
            setCurrentSection(null)
        } else {
            startSectionStudy(userName, currentBook._id!.toString(), currentBook.title, chapterID, chapterTitle, sections[sectionCounter]._id, sections[sectionCounter].title)
            setCurrentSection(sections[sectionCounter])
            refTitle.current = sections[sectionCounter].title
        }
    }, [sectionCounter]);

    const handleRead_Click = async () => {
        stopSpeaking()
        setReadingComplete(false)
        await speakWithEndFlag(currentSection?.paragraphs[paraCounter]?.text!)
        setReadingComplete(true)
    }

    const handleNext_Click = async () => {
        stopSpeaking()
        setReadingComplete(false)
        setParaCounter(prevState => prevState + 1)
    }

    const handleRestart_Click = () => {
        setParaCounter(0)
        setSectionCounter(0)
        setCurrentSection(sections[0])
    }

    const handleNextActivity_Click = () => {
        appDispatch(update(Page.CHOOSE_CORRECT_ANSWER_IN_CHAPTER))
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <Header/>
            <Title
                title={refTitle.current}
            />
            <Description description={`of '${refDesc.current}'`}/>
            <Body>
                <div style={{width: '90%'}}>
                    {
                        ((): ReactNode => {
                            const text = currentSection?.paragraphs[paraCounter]?.text;

                            // Return null if text is not defined
                            if (!text) {
                                return null;
                            }

                            const sentences = [currentSection?.paragraphs[paraCounter]?.text]

                            if (sentences[sentences?.length - 1]?.trim() === "") {
                                sentences.pop()
                            }

                            return (
                                sentences.map((sentence, sentenceIndex) => (
                                    <Box key={sentenceIndex}
                                         sx={{
                                             marginBottom: '10px',
                                             display: 'flex',
                                             alignItems: 'center',
                                             justifyContent: 'center',
                                         }}
                                    >
                                        <Sheet key={sentenceIndex}
                                               sx={{
                                                   fontSize: 'large',
                                               }}
                                        >
                                            {
                                                parse(highlightAnswersInParagraph(
                                                    sentence.endsWith('.') ? sentence : sentence.endsWith('?') ? sentence : `${sentence}.`, answersToShow, sentenceIndex), {
                                                    replace(domNode) {
                                                        const domElement: Element = domNode as Element;

                                                        if (domElement.name === 'chip' && domElement.children[0].type === 'text') {
                                                            return (
                                                                <>
                                                                    &nbsp;
                                                                    <Chip
                                                                        {...domElement.attribs}
                                                                        sx={{
                                                                            verticalAlign: 'bottom',
                                                                            backgroundColor: readingComplete ?
                                                                                'green' : 'grey'
                                                                        }}
                                                                        size='lg'
                                                                        startDecorator={readingComplete ?
                                                                            <CheckCircleOutlined/> :
                                                                            <Pending/>}
                                                                    >
                                                                        {(domElement.children[0]).data}
                                                                    </Chip>
                                                                    &nbsp;
                                                                </>
                                                            );
                                                        }
                                                    }
                                                })
                                            }
                                        </Sheet>
                                    </Box>
                                ))
                            )
                        })()
                    }
                </div>
            </Body>
            <ButtonRow>
                {
                    (() => {
                        if (currentSection !== null) {
                            return (
                                <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                    <Button variant="outlined"
                                            sx={resizeButtonSX()}
                                            onClick={handleNext_Click}
                                    >Next</Button>
                                    <Button variant="solid" color="primary"
                                            sx={resizeButtonSX()}
                                            onClick={handleRead_Click}
                                    >Read</Button>
                                </ButtonGroup>
                            )
                        }
                        return (
                            <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                <Button variant="outlined"
                                        sx={resizeButtonSX()}
                                        onClick={handleRestart_Click}
                                >Restart</Button>
                                <Button variant="solid" color="primary"
                                        sx={resizeButtonSX()}
                                        onClick={handleNextActivity_Click}
                                >Next Activity</Button>
                            </ButtonGroup>
                        )
                    })()
                }
            </ButtonRow>
            <ActivityFooter currentIndex={currentSection ? sections.indexOf(currentSection) : sections.length}
                            totalCount={sections.length}/></div>
    )
}