import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {
    incrementCurrentQuestionIndex, setChapterResult,
    setCorrectAnswer,
    setCurrentTrafficLight,
    setQuestionHasBeenRead
} from "src/slices/chapterSlice";
import {IChapterAssessment, saveChapterAssessment} from "src/slices/chapterAssessmentSlice";
import {isNumber} from "lodash";
import {getDuration} from "src/utils/functions";
import {useRef} from "react";
import {distance} from "fastest-levenshtein";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import GreenTrafficLight from "src/images/green_traffic_light.png"
import RedTrafficLight from "src/images/red_traffic_light.png"
import YellowTrafficLight from "src/images/yellow_traffic_light.png"
import {ChapterResult} from "src/utils/constants";

export const enum CompareAnswerResult {
    CORRECT = 'correct',
    INCORRECT = 'incorrect',
    TYPO = 'typo'
}

export const useChapterMgr = () => {
    const refStartDuration = useRef(new Date().getTime());
    const chapterID = useAppSelector((state: RootState) => state.chapter.chapterID)
    const currentSectionID = useAppSelector((state: RootState) => state.chapter.currentSectionID)
    const currentQuestionIndex = useAppSelector((state: RootState) => state.chapter.currentQuestionIndex);
    const userAnswer = useAppSelector((state: RootState) => state.chapter.userAnswer);
    const extractedQsandAs = useAppSelector((state: RootState) => state.chapter.extractedQsandAs);

    const appDispatch = useAppDispatch();

    const {playCorrectAudio, playWrongAudio} = useSharedMgr();

    const getChapterResult = (chapterResult: IChapterAssessment): ChapterResult => {
        if (chapterResult.incorrect_answer && chapterResult.incorrect_answer.length > 0) {
            return ChapterResult.INCORRECT;
        }

        if (chapterResult.typo_in_answer && chapterResult.typo_in_answer.length > 0) {
            return ChapterResult.TYPO;
        }

        if (chapterResult.incorrect_answer !== undefined && chapterResult.incorrect_answer.length === 0) {
            return ChapterResult.SKIPPED;
        }

        return ChapterResult.CORRECT;
    };

    const getChapterResultCounts = (chapterResults: IChapterAssessment[]): Record<ChapterResult, number> => {
        const resultCounts: Record<ChapterResult, number> = {
            [ChapterResult.CORRECT]: 0,
            [ChapterResult.INCORRECT]: 0,
            [ChapterResult.TYPO]: 0,
            [ChapterResult.SKIPPED]: 0,
        };

        chapterResults.forEach((result) => {
            const chapterResult = getChapterResult(result);
            resultCounts[chapterResult]++;
        });

        return resultCounts;
    };

    const assessmentIsValid = (results: IChapterAssessment[]) => {
        const countOfQuestionsWhereAnswerWasNotAttempted = results.filter(result => (result.incorrect_answer === "" && result.typo_in_answer === "")).length;

        return ((results.length - countOfQuestionsWhereAnswerWasNotAttempted) / results.length) > .74;
    };

    const answerIsCorrectValue = (decimalDiff: number, correctAnswer: string | number, correctAnswerLength: number): CompareAnswerResult => {
        if (decimalDiff === 0)
            return CompareAnswerResult.CORRECT

        if (decimalDiff < .20) {
            if (String(correctAnswer).includes(' ')) {
                // If userAnswer is a multi-word phrase, do an additional check to determine if the answer should be specified as correct or a typo.
                if (decimalDiff < .08) {
                    return CompareAnswerResult.CORRECT
                } else {
                    return CompareAnswerResult.TYPO
                }
            } else if (correctAnswerLength > 5) {
                // If userAnswer is a word greater than 5 characters, return 'typo' since the diff falls below the threshold.
                return CompareAnswerResult.TYPO
            } else {
                // userAnswer is a single word less than 6 characters, so the strings must match exactly.
                return decimalDiff === 0 ? CompareAnswerResult.CORRECT : CompareAnswerResult.INCORRECT
            }
        }

        return CompareAnswerResult.INCORRECT
    };

    const processArray = (input: string): string => {
        // Step 1: Replace 'and' with ',' and remove whitespace from the input string
        const modifiedInput = input.replace(/\band\b/g, ',').replace(/\s+/g, '');

        // Step 2: Split the string by comma
        const array = modifiedInput.split(',');

        // Step 3: Sort the array alphabetically
        const sortedArray = array.sort();

        // Step 4: Join the sorted array back into a string
        return sortedArray.join(',');
    };

    const answerIsCorrect = (correctAnswer: string | number, typedAnswer: string | number | null = null): CompareAnswerResult => {
        const typedValueToCheck = typedAnswer ? typedAnswer : userAnswer

        if (isNumber(correctAnswer)) {
            if (!isNaN(Number(typedValueToCheck))) {
                return correctAnswer === Number(typedValueToCheck) ? CompareAnswerResult.CORRECT : CompareAnswerResult.INCORRECT;
            } else {
                // A string is being compared to the correct answer, which is a number. They can't be equal.
                return CompareAnswerResult.INCORRECT
            }
        } else {
            // Correct Answer is a string.
            // Replace " or " && " and " && " & " with "," && "percent" with "%
            let trimmedLowerCaseCorrectAnswer = correctAnswer?.replace(/\sor\s/g, ',').replace(/\sand\s/g, ',').replace(/\s&\s/g, ',').replace(/\s*percent\s*/g, '%').trim().toLowerCase()
            const correctAnswerLength: number = correctAnswer?.trim().length

            if (isNaN(Number(typedValueToCheck))) {
                // User Answer is a string.
                // Replace " or " && " and " && " & " with "," && "percent" with "%
                let trimmedLowerCaseUserAnswer = typedValueToCheck.toString().replace(/\sor\s/g, ',').replace(/\sand\s/g, ',').replace(/\s&\s/g, ',').replace(/\s*percent\s*/g, '%').trim().toLowerCase()

                if (trimmedLowerCaseUserAnswer.includes(',') && trimmedLowerCaseCorrectAnswer.includes(',')) {
                    trimmedLowerCaseUserAnswer = processArray(trimmedLowerCaseUserAnswer)
                    trimmedLowerCaseCorrectAnswer = processArray(trimmedLowerCaseCorrectAnswer)
                }

                const distanceVal = distance(trimmedLowerCaseCorrectAnswer, trimmedLowerCaseUserAnswer);
                const decimalDiff = distanceVal / correctAnswerLength;

                return answerIsCorrectValue(decimalDiff, correctAnswer, correctAnswerLength);
            } else {
                // User Answer is a number.
                const distanceVal = distance(trimmedLowerCaseCorrectAnswer, typedValueToCheck.toString().trim().toLowerCase())
                const decimalDiff: number = distanceVal / correctAnswerLength

                return answerIsCorrectValue(decimalDiff, correctAnswer, correctAnswerLength);
            }
        }
    }

    const submitAnswer = () => {
        const currentQuestionInfo = extractedQsandAs![currentQuestionIndex]
        appDispatch(setQuestionHasBeenRead(false))
        appDispatch(setCorrectAnswer(currentQuestionInfo?.answer))

        const chapterAssessment: IChapterAssessment = {
            chapter_id: chapterID,
            section_id: currentSectionID,
            correct_answer: currentQuestionInfo?.answer,
            duration: getDuration(refStartDuration),
            paragraph_id: currentQuestionInfo?.para_id,
            qa_id: currentQuestionInfo?.qa_id,
            question: currentQuestionInfo?.question
        }

        const answerIsCorrectResult: CompareAnswerResult = answerIsCorrect(currentQuestionInfo?.answer)

        if (answerIsCorrectResult === CompareAnswerResult.CORRECT) {
            appDispatch(setCurrentTrafficLight(GreenTrafficLight))
            playCorrectAudio()
            appDispatch(saveChapterAssessment(
                chapterAssessment
            ))
        } else if (answerIsCorrectResult === CompareAnswerResult.TYPO) {
            appDispatch(setCurrentTrafficLight(GreenTrafficLight))
            appDispatch(setChapterResult(ChapterResult.TYPO))
            playWrongAudio()
            chapterAssessment.typo_in_answer = userAnswer
            appDispatch(saveChapterAssessment(
                chapterAssessment
            ))
        } else {
            if (userAnswer.trim() === '') {
                appDispatch(setChapterResult(ChapterResult.SKIPPED))
                appDispatch(setCurrentTrafficLight(YellowTrafficLight))
            } else {
                appDispatch(setChapterResult(ChapterResult.INCORRECT))
                appDispatch(setCurrentTrafficLight(RedTrafficLight))
            }

            playWrongAudio()
            chapterAssessment.incorrect_answer = userAnswer
            appDispatch(saveChapterAssessment(
                chapterAssessment
            ))
        }

        if (currentQuestionIndex < extractedQsandAs.length) {
            appDispatch(incrementCurrentQuestionIndex())
        }
    }

    const highlightAnswersInParagraph = (paragraphText: string, searchStrings: string[], index?: number): string => {
        let result = paragraphText;

        // The regular expression pattern [^\w\s] matches any character that is not a word character (\w) or a whitespace character (\s).
        const pattern = /[^\w\s]/;

        if (pattern.test(paragraphText)) {
            // The paragraphText does not only contain alphanumeric characters.
            searchStrings.forEach(searchString => {
                if (searchString.includes(' ')) {
                    const position = result.toLowerCase().indexOf(searchString.toLowerCase());

                    if (position !== -1) {
                        const highlightedString = `<Chip variant='solid' color='warning' id=${index}>${result.slice(position, position + searchString.length)}</Chip>`;
                        result = result.slice(0, position) + highlightedString + result.slice(position + searchString.length);
                    }
                } else {
                    // Look for an exact match in paragraph Text
                    const regex = new RegExp(`\\b${searchString}\\b`, 'gi');
                    result = result.replace(regex, `<Chip variant='solid' color='warning' id=${index}>${searchString}</Chip>`);
                }
            });
        } else {
            searchStrings.forEach(searchString => {
                const regex = new RegExp(`(${searchString})`, 'gi');
                result = result.replace(regex, `<Chip variant='solid' color='warning' id=${index}>$1</Chip>`);
            });
        }

        return result;
    }

    return {
        answerIsCorrect,
        assessmentIsValid,
        getChapterResult,
        getChapterResultCounts,
        submitAnswer,
        highlightAnswersInParagraph
    };
}