import React, {CSSProperties, FC, useEffect, useState} from "react";
import {Header} from "src/components/Header";
import {Input, Snackbar} from "@mui/joy";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import 'src/styles/spelling.css';
import {useCountdown, useInterval} from "usehooks-ts";
import {SpellingResult, useSpellingMgr} from "src/hooks/useSpellingMgr";
import {RootState} from "src/app/store";
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {Page, update} from "src/slices/pageSlice";
import {ActivityFooter} from "src/components/Spelling/ActivityFooter";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import {useParams} from "react-router-dom";
import {getMod, startSpellingStudy} from "src/utils/api-service";
import {IBookMod, IMod} from "@backend/mongoose.gen";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import {saveSelectedSpellingInfo, saveSpellingStudyID} from "src/slices/wordsSlice";
import ObjectID from "bson-objectid";
import {newObjectId} from "src/utils/functions";
import Typography from "@mui/joy/Typography";
import {ISpellingAssessment} from "src/slices/spellingAssessmentSlice";
import {z} from "zod";
import {BaseIModRecordSchema} from "src/zodSchemas";
import {LocalSettings, LocalSettingsUI_Direction} from "src/components/Shared/LocalSettings";
import {saveUserName, setValueOfSetFocusOnUsername} from "src/slices/settingsSlice";
import {MobileAccessNotSupported} from "src/components/Shared/MobileAccessNotSupported";
import {isMobile} from "react-device-detect";

export const StudyWords: FC = () => {
    const [currentStudyWordIndex, setCurrentStudyWordIndex] = useState<number>(-1);
    const [currentStudyWordLength, setCurrentStudyWordLength] = useState<number>(-1);
    const [buttonText, setButtonText] = useState<string>("Start");
    const [wordShouldBePronouncedAfterSpelling, setWordShouldBePronouncedAfterSpelling] = useState<boolean>(false)
    const [studyWordButtonShouldBeDisabled, setStudyWordButtonShouldBeDisabled] = useState<boolean>(false)
    const [wordsToStudy, setWordsToStudy] = useState<string[]>([])
    const [misspelledAndSkippedWords, setMisspelledAndSkippedWords] = useState<(string | undefined)[]>([])
    const [IDWasInURL, setIDWasInURL] = useState(false)
    const [modRetrievalFailure, setModRetrievalFailure] = useState(false)
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [showNotAvailableInMobileMsg, setShowNotAvailableInMobileMsg] = useState(false);

    const {id} = useParams();
    const appDispatch = useAppDispatch();

    const assessmentCompletedBeforeStudying = useAppSelector((state: RootState) => state.words.assessmentCompletedBeforeStudying)

    const results = useAppSelector((state: RootState) => state.spellingAssessment.data);
    const title = useAppSelector((state: RootState) => state.words.title)
    const words = useAppSelector((state: RootState) => state.words.words);
    const spellingID = useAppSelector((state: RootState) => state.words.spellingID);
    const userName = useAppSelector((state: RootState) => state.settings.userName);

    const {getSpellingResult} = useSpellingMgr();
    const {setNavigationPathToHomeIfNeeded, speak} = useSharedMgr();

    const [letterPosition, {resetCountdown, startCountdown}] =
        useCountdown({
            countStart: 0,
            intervalMs: 1500,
            isIncrement: true,
            countStop: currentStudyWordLength
        })

    useInterval(
        () => {
            speak(wordsToStudy[currentStudyWordIndex])
            setWordShouldBePronouncedAfterSpelling(false)
            setStudyWordButtonShouldBeDisabled(false)
        },
        wordShouldBePronouncedAfterSpelling ? 1500 : null
    )

    const retrievalFailure = (error: string) => {
        console.log(`Error: ${error}`)
        setModRetrievalFailure(true)
    }

    const modRetrieved = (modData: IBookMod | IMod) => {
        if ('words' in modData) {
            appDispatch(saveSelectedSpellingInfo({
                spellingID: modData._id.toString(),
                title: modData.title,
                words: modData.words
            }))
            // setWordsToStudy(modData.words.map(item => item.word))
        } else {
            retrievalFailure("Error: Mod data not found")
        }
    };

    const fillMisspelledAndSkippedWordsWithBlanks = (spellingAssessments: ISpellingAssessment[]) =>
        spellingAssessments.map((item) => {
            const word = item.word;
            let misspelling = item.misspelling || '';

            // If misspelling is undefined or null, fill with blanks to match word length
            if (misspelling.length === 0) {
                misspelling = '?'.repeat(word.length);
            } else if (misspelling.length < word.length) {
                // If misspelling is shorter than word, add padding
                let result = '';
                let misspellingIndex = 0;
                for (let i = 0; i < word.length; i++) {
                    if (misspellingIndex < misspelling.length && word[i] === misspelling[misspellingIndex]) {
                        result += misspelling[misspellingIndex];
                        misspellingIndex++;
                    } else {
                        result += ' ';
                    }
                }
                misspelling = result;
            }

            return misspelling;
        });

    useEffect(() => {
        if (assessmentCompletedBeforeStudying) {
            const misspelledAndSkippedWords = results.filter(result => [SpellingResult.MISSPELLED, SpellingResult.SKIPPED].includes(getSpellingResult(result)))

            // If a misspelled word is shorter than the corresponding correctly spelled word, put blanks to fill the spaces.
            setMisspelledAndSkippedWords(fillMisspelledAndSkippedWordsWithBlanks(misspelledAndSkippedWords))
            setWordsToStudy(misspelledAndSkippedWords.map(result => result.word))
        } else {
            if (id) {
                setIDWasInURL(true)

                if (isMobile) {
                    setShowNotAvailableInMobileMsg(true)
                    return
                }

                setModRetrievalFailure(false)
                getMod(id, modRetrieved, retrievalFailure)
                appDispatch(update(Page.STUDY_WORDS))
            } else {
                setWordsToStudy(words?.map(item => item.word))
            }
        }
    }, []);

    useEffect(() => {
        if (currentStudyWordIndex === -1) {
            setButtonText("Start");
        } else if (currentStudyWordIndex < wordsToStudy.length) {
            speak(wordsToStudy[currentStudyWordIndex])
            setCurrentStudyWordLength(wordsToStudy[currentStudyWordIndex].length)
            setButtonText("Next");
            resetCountdown();
            startCountdown();
        } else if (currentStudyWordIndex >= wordsToStudy.length) {
            setNavigationPathToHomeIfNeeded(IDWasInURL)
            appDispatch(update(Page.CHOOSE_CORRECT_SPELLING))
        }

        if (currentStudyWordIndex === 0) {
            const spellingStudyToStart: z.infer<typeof BaseIModRecordSchema> = {
                _id: ObjectID(newObjectId()),
                mod_id: ObjectID(spellingID),
                username: userName
            }

            startSpellingStudy(spellingStudyToStart,
                (newSpellingStudyRecordID) => {
                    appDispatch(saveSpellingStudyID(newSpellingStudyRecordID))
                },
                (error: any) => console.error("Error:", error)
            )
        }
    }, [currentStudyWordIndex])

    useEffect(() => {
        const correctLetterInput = document.getElementById(`correct-letter-${letterPosition}`) as HTMLInputElement;
        const correctLetterParentDiv = correctLetterInput?.parentNode as HTMLElement;
        let userLetterInput = document.getElementById(`user-letter-${letterPosition}`) as HTMLInputElement;
        let userLetterParentDiv = userLetterInput?.parentNode as HTMLElement;

        if (correctLetterInput && correctLetterParentDiv) {
            speak(wordsToStudy[currentStudyWordIndex][letterPosition - 1])
            correctLetterParentDiv.style.animation = 'none';
            correctLetterParentDiv.style.backgroundColor = 'green'
            correctLetterInput.value = wordsToStudy[currentStudyWordIndex][letterPosition - 1];

            if (letterPosition === currentStudyWordLength) {
                setWordShouldBePronouncedAfterSpelling(true)

                if (currentStudyWordIndex === (wordsToStudy.length - 1)) (
                    setButtonText("Next Activity")
                )
            }
        }

        // Misspelled or Wrong Input
        if (userLetterInput && userLetterParentDiv) {
            if (wordsToStudy[currentStudyWordIndex][letterPosition - 1] === misspelledAndSkippedWords[currentStudyWordIndex]![letterPosition - 1]) {
                userLetterParentDiv.style.backgroundColor = 'green'
            } else {
                userLetterParentDiv.style.backgroundColor = 'red'
            }

            // If all correct letters have been shown, and the misspelled word's length is greater than the length of the correct word, show the remaining letters in yellow.
            if (letterPosition === currentStudyWordLength) {
                if (misspelledAndSkippedWords[currentStudyWordIndex]!.length > currentStudyWordLength) {
                    for (let i = letterPosition + 1; i <= misspelledAndSkippedWords[currentStudyWordIndex]!.length; i++) {
                        userLetterInput = document.getElementById(`user-letter-${i}`) as HTMLInputElement;
                        userLetterParentDiv = userLetterInput?.parentNode as HTMLElement;
                        userLetterParentDiv.style.backgroundColor = 'red'
                    }
                }
            }
        }
    }, [letterPosition])

    const animationStyle: CSSProperties = {
        animation: 'rotate 4s linear infinite',
        transformOrigin: 'center'
    };

    return (
        <>
            {
                (() => {
                    if (isMobile) return <Header/>
                    if (IDWasInURL && modRetrievalFailure) return (
                        <ModOrPageNotFound notFoundType={NotFoundType.Mod}/>
                    )

                    return (
                        <div style={{height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                            <Header/>
                            <Title title={title}/>
                            <Description description={'Study the Words as They Appear'}/>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto'}}>
                                <Body>
                                    {
                                        (() => {
                                            if (buttonText === 'Start') {
                                                return (
                                                    <LocalSettings direction={LocalSettingsUI_Direction.column}/>
                                                )
                                            }

                                            if (currentStudyWordIndex > -1) return (
                                                <>
                                                    <div style={{marginBottom: '20px'}}>
                                                        <Typography
                                                            fontSize="x-large"
                                                            fontWeight="lg"
                                                        >
                                                            Correct Spelling
                                                        </Typography>
                                                    </div>
                                                    <div style={{display: 'flex', flexDirection: 'row'}}
                                                         key={`correct-spelling-${currentStudyWordIndex}`}>
                                                        {
                                                            wordsToStudy[currentStudyWordIndex]?.split('').map((letter, mapIndex) => (
                                                                <Input id={`correct-letter-${mapIndex + 1}`} size='lg'
                                                                       value={mapIndex < letterPosition ? letter : '?'}
                                                                       variant="outlined"
                                                                       key={mapIndex} disabled
                                                                       sx={{
                                                                           margin: '5px',
                                                                           "& input": {
                                                                               color: 'white',
                                                                               fontSize: '40px',
                                                                               textAlign: "center",
                                                                               width: '32px',
                                                                           }
                                                                       }}
                                                                       style={animationStyle}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                    {
                                                        (() => {
                                                            if (misspelledAndSkippedWords.length > 0) return (
                                                                <>
                                                                    <div style={{
                                                                        marginBottom: '20px',
                                                                        marginTop: '50px'
                                                                    }}>
                                                                        <Typography
                                                                            fontSize="x-large"
                                                                            fontWeight="lg"
                                                                        >
                                                                            Your Spelling
                                                                        </Typography>
                                                                    </div>
                                                                    <div style={{display: 'flex', flexDirection: 'row'}}
                                                                         key={`user-spelling-${currentStudyWordIndex}`}>
                                                                        {
                                                                            misspelledAndSkippedWords[currentStudyWordIndex]?.split('').map((letter, mapIndex) => (
                                                                                <Input
                                                                                    id={`user-letter-${mapIndex + 1}`}
                                                                                    size='lg'
                                                                                    value={letter}
                                                                                    variant="outlined"
                                                                                    key={mapIndex}
                                                                                    disabled
                                                                                    sx={{
                                                                                        margin: '5px',
                                                                                        background: letter === ' ' || letter === '?' ? 'yellow' : null,
                                                                                        "& input": {
                                                                                            color: 'white',
                                                                                            fontSize: '40px',
                                                                                            textAlign: "center",
                                                                                            width: '32px',
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            ))
                                                                        }
                                                                    </div>
                                                                    <div style={{
                                                                        marginBottom: '20px',
                                                                        visibility: misspelledAndSkippedWords[currentStudyWordIndex]?.split('').every((char) => char === '?') ? 'visible' : 'hidden'
                                                                    }}>
                                                                        <Typography
                                                                            fontSize="large"
                                                                            fontWeight="lg"
                                                                        >
                                                                            (This Word Was Skipped)
                                                                        </Typography>
                                                                    </div>
                                                                </>
                                                            )
                                                        })()
                                                    }
                                                </>
                                            )
                                        })()
                                    }
                                </Body>
                            </div>
                            <ButtonRow>
                                <Button variant="solid" color="primary"
                                        disabled={studyWordButtonShouldBeDisabled}
                                        sx={resizeButtonSX()}
                                        onClick={() => {
                                            if (buttonText === 'Start') {
                                                if (userName.trim() === '') {
                                                    appDispatch(setValueOfSetFocusOnUsername(true))
                                                    setShowSnackbarMsg(true)
                                                    return
                                                }

                                                appDispatch(saveUserName(userName.trim()))
                                            }

                                            setStudyWordButtonShouldBeDisabled(true)
                                            setCurrentStudyWordIndex(prevState => prevState + 1)
                                        }}>{buttonText}</Button>
                            </ButtonRow>
                            <ActivityFooter currentIndex={currentStudyWordIndex} totalCount={wordsToStudy.length}/>
                        </div>
                    )
                })()
            }
            {
                (() => {
                    if (showSnackbarMsg)
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color={"warning"}
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                autoHideDuration={3000}
                                open={true}
                                onClose={() => {
                                    setShowSnackbarMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                Name is required.
                            </Snackbar>
                        )
                })()
            }
            {
                (() => {
                    if (showNotAvailableInMobileMsg) {
                        return (
                            <MobileAccessNotSupported onClose={() => {
                                setShowNotAvailableInMobileMsg(false);
                                setNavigationPathToHomeIfNeeded(IDWasInURL)
                                appDispatch(update(Page.HOME))
                            }}
                            />
                        )
                    }
                })()
            }
        </>
    )
}