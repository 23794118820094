import React, {FC, useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "src/app/hooks";
import {RootState} from "src/app/store";
import {useLazyGetBookQuery, useLazyGetChapterQuery} from "src/utils/api-service";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import {Box, List, ListItem, ListItemButton, ListItemDecorator, Radio, RadioGroup, Snackbar} from "@mui/joy";
import Body from "src/components/Shared/Body";
import ButtonRow from "src/components/Shared/ButtonRow";
import ModOrPageNotFound, {NotFoundType} from "src/components/Shared/ModOrPageNotFound";
import ButtonGroup from "@mui/joy/ButtonGroup";
import Button from "@mui/joy/Button";
import {resizeButtonSX} from "src/utils/ui";
import {Page, update} from "src/slices/pageSlice";
import {useSharedMgr} from "src/hooks/useSharedMgr";
import {IBookModChapter} from "@backend/mongoose.gen";
import Title from "src/components/Shared/Title";
import Description from "src/components/Shared/Description";
import {formatDate} from "src/utils/functions";
import {
    LocalSettings,
    LocalSettingsUI_Direction
} from "src/components/Shared/LocalSettings";
import {saveSelectedChapterInfo, setCurrentSectionID} from "src/slices/chapterSlice";
import {useParams} from "react-router-dom";
import {z} from "zod";
import {IBookModSchema} from "src/zodSchemas";
import {setAssessmentCompletedBeforeStudying} from "src/slices/wordsSlice";
import {setCurrentBook} from "src/slices/bookSlice";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";
import ObjectID from "bson-objectid";
import {saveUserName, setValueOfSetFocusOnUsername} from "src/slices/settingsSlice";
import Card from "@mui/joy/Card";
import {isMobile} from 'react-device-detect';
import {MobileAccessNotSupported} from "src/components/Shared/MobileAccessNotSupported";
import {Header} from "src/components/Header";

export const SelectChapterToStudy: FC = () => {
    const [IDWasInURL, setIDWasInURL] = useState(false)
    const [selectedChapterID, setSelectedChapterID] = useState('')
    const [snackBarMsg, setSnackBarMsg] = useState('')
    const [showSnackbarMsg, setShowSnackbarMsg] = useState(false)
    const [selectedSections, setSelectedSections] = useState<string[]>([])
    const [modRetrievalFailure, setModRetrievalFailure] = useState(false)
    const [showNotAvailableInMobileMsg, setShowNotAvailableInMobileMsg] = useState(false);

    const currentBook = useAppSelector((state: RootState) => state.book.currentBook);
    const userName = useAppSelector((state: RootState) => state.settings.userName);

    const [getBook, {
        data: bookData,
        isError: getBookError,
        fulfilledTimeStamp: bookFulfilledTimeStamp
    }] = useLazyGetBookQuery();
    const [getChapter, {data: chapterData, fulfilledTimeStamp: chapterFulfilledTimeStamp}] = useLazyGetChapterQuery();

    const {id} = useParams();
    const appDispatch = useAppDispatch();
    const {setNavigationPathToHomeIfNeeded} = useSharedMgr();

    useEffect(() => {
        setIDWasInURL(!!id)
        appDispatch(setAssessmentCompletedBeforeStudying(false))

        if (id) {
            // A Link to Study a Book has been entered
            if (isMobile) {
                setShowNotAvailableInMobileMsg(true)
                return
            }

            getBook(
                {
                    bookId: id
                }
            );
        }
    }, []);

    useEffect(() => {
        if (bookFulfilledTimeStamp) {
            const bookDataInstance: z.infer<typeof IBookModSchema> = {
                ...bookData?.data!,
                _id: ObjectID(bookData!.data!._id!.toString())
            };

            appDispatch(setCurrentBook(bookDataInstance))
        }
    }, [bookFulfilledTimeStamp]);

    useEffect(() => {
        if (getBookError) {
            setModRetrievalFailure(true)
        }
    }, [getBookError]);

    useEffect(() => {
        if (chapterFulfilledTimeStamp) {
            const chapterDataInstance: IBookModChapter = chapterData?.data!;

            appDispatch(saveSelectedChapterInfo({
                chapterID: selectedChapterID,
                title: chapterDataInstance.title,
                sections: chapterDataInstance.sections,
                assessmentCompletedBeforeStudying: false
            }))

            setNavigationPathToHomeIfNeeded(IDWasInURL)
            appDispatch(update(Page.STUDY_SECTIONS))
        }
    }, [chapterFulfilledTimeStamp]);

    const selectAllSections = (chapterID: string) => {
        const newSelectedSections: string[] = []

        currentBook.chapters?.find(chapter => chapter._id === chapterID)?.sections.forEach((section) => {
            newSelectedSections.push(section._id);
        });

        setSelectedSections(newSelectedSections);
    };

    const handleChapterRadioButtonClick = (chapterID: string) => {
        if (selectedChapterID !== chapterID) {
            setSelectedChapterID(chapterID);
            selectAllSections(chapterID)
        }
    };

    const handleSectionListItemClick = (sectionID: string) => {
        if (selectedSections.includes(sectionID)) {
            setSelectedSections([...selectedSections.filter(selectedSection => selectedSection !== sectionID)])
        } else {
            setSelectedSections([...selectedSections, sectionID])
        }
    };

    const handleGo_Click = () => {
        if (userName.trim() === '') {
            setSnackBarMsg('Name is required.')
            setShowSnackbarMsg(true)
            appDispatch(setValueOfSetFocusOnUsername(true))
            return
        }

        if (selectedChapterID === '') {
            setSnackBarMsg('You must Select the Chapter to Study')
            setShowSnackbarMsg(true)
            return
        }

        if (selectedSections.length === 0) {
            setSnackBarMsg('You must select at least one Section to Study')
            setShowSnackbarMsg(true)
            return
        }

        appDispatch(setCurrentSectionID(selectedSections.sort()[0]))
        appDispatch(saveUserName(userName.trim()))
        getChapter(
            {
                bookID: id ? id : currentBook._id!.toString(),
                chapterID: selectedChapterID,
                sectionIDs: selectedSections
            }
        );
    }

    return (
        <>
            {
                (() => {
                    if (isMobile) return <Header/>
                    if (IDWasInURL && modRetrievalFailure) return (
                        <ModOrPageNotFound notFoundType={NotFoundType.Mod}/>
                    )

                    return (
                        <div style={{height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                            <Sheet
                                sx={{
                                    background: "linear-gradient(to right, #48BB78, #3B82F6)",
                                    width: "100vw",
                                    height: "100vh",
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <Title title={`Select a Chapter to Study
                                               from '${currentBook.title}'`}/>
                                <Description description={formatDate(new Date())}/>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto'}}>
                                    <Body boxGap={2}>
                                        <LocalSettings direction={LocalSettingsUI_Direction.row}/>
                                        <div style={{display: 'flex', gap: '25px'}}>
                                            <Sheet
                                                variant="outlined"
                                                sx={{
                                                    boxShadow: 'sm',
                                                    borderRadius: 'sm',
                                                    p: 2,
                                                    width: '450px',
                                                    height: '250px',
                                                }}
                                            >
                                                <Typography
                                                    level="h4"
                                                    paddingBottom="15px"
                                                >
                                                    Available Chapters
                                                </Typography>
                                                <RadioGroup
                                                    size="lg"
                                                    orientation="vertical"
                                                    value={selectedChapterID}
                                                    name="radio-buttons-group"
                                                    sx={{
                                                        height: 210,
                                                        overflowY: 'auto',
                                                        overflowX: 'hidden'
                                                    }}>
                                                    {currentBook.chapters.map((chapter) => {
                                                        return (
                                                            <Radio value={chapter._id} label={chapter.title}
                                                                   key={chapter._id}
                                                                   sx={{
                                                                       '& .MuiRadio-label': {
                                                                           overflow: 'hidden',
                                                                           textOverflow: 'ellipsis',
                                                                           whiteSpace: 'nowrap',
                                                                           maxWidth: '380px'
                                                                       }
                                                                   }}
                                                                   onClick={() => handleChapterRadioButtonClick(chapter._id)}/>
                                                        )
                                                    })}
                                                </RadioGroup>
                                            </Sheet>
                                            <Sheet
                                                variant="outlined"
                                                sx={{
                                                    boxShadow: 'sm',
                                                    borderRadius: 'sm',
                                                    p: 2,
                                                    width: '450px',
                                                    height: '250px'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {
                                                        (() => {
                                                            if (selectedSections.length === currentBook.chapters?.find(chapter => chapter._id === selectedChapterID)?.sections?.length) {
                                                                return (
                                                                    <CheckBox
                                                                        sx={{
                                                                            paddingLeft: '13px',
                                                                            paddingRight: '14px',
                                                                            color: '#0b6bcb',
                                                                            cursor: 'pointer'
                                                                        }}
                                                                        onClick={() => {
                                                                            setSelectedSections([])
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            return (
                                                                <CheckBoxOutlineBlank
                                                                    sx={{
                                                                        paddingLeft: '13px',
                                                                        paddingRight: '14px',
                                                                        color: '#0b6bcb',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        selectAllSections(selectedChapterID)
                                                                    }}
                                                                />
                                                            )
                                                        })()
                                                    }
                                                    <Typography
                                                        level="h4"
                                                    >
                                                        Choose the Sections You Want to Study
                                                    </Typography>
                                                </Box>
                                                <List
                                                    sx={{
                                                        height: 210,
                                                        overflow: 'auto'
                                                    }}
                                                >
                                                    {currentBook.chapters?.find(chapter => chapter._id === selectedChapterID)?.sections.map((section) => (
                                                        <ListItem key={section._id}>
                                                            <ListItemButton
                                                                selected={selectedSections.includes(section._id)}
                                                                onClick={() => handleSectionListItemClick(section._id)}
                                                            >
                                                                <ListItemDecorator sx={{color: '#0b6bcb'}}>
                                                                    {selectedSections.includes(section._id) ?
                                                                        <CheckBox/> : <CheckBoxOutlineBlank/>
                                                                    }
                                                                </ListItemDecorator>
                                                                <div style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    maxWidth: '380px',
                                                                    fontFamily: 'Arial',
                                                                    fontSize: '17px'
                                                                }}>
                                                                    {section.title}
                                                                </div>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Sheet>
                                        </div>
                                        {
                                            (() => {
                                                if (currentBook.notes) {
                                                    return (
                                                        <Card variant="soft"
                                                              sx={{
                                                                  width: '50%',
                                                                  maxHeight: 200
                                                              }}
                                                        >
                                                            <Typography
                                                                level="h4"
                                                            >
                                                                Book Notes
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    overflow: 'auto',
                                                                    paddingRight: '20px'
                                                                }}
                                                                dangerouslySetInnerHTML={{__html: currentBook.notes}}
                                                            />
                                                        </Card>
                                                    )
                                                }
                                            })()
                                        }
                                    </Body>
                                </div>
                                <ButtonRow>
                                    <ButtonGroup variant="outlined" sx={{bgcolor: 'background.surface'}}>
                                        <Button
                                            sx={resizeButtonSX()}
                                            onClick={
                                                () => {
                                                    setNavigationPathToHomeIfNeeded(IDWasInURL);
                                                    appDispatch(update(Page.HOME))
                                                }
                                            }
                                        >Cancel</Button>
                                        <Button variant="solid" color="primary"
                                                sx={resizeButtonSX()}
                                                onClick={handleGo_Click}>Go</Button>
                                    </ButtonGroup>
                                </ButtonRow>
                            </Sheet>
                        </div>
                    )
                })()
            }
            {
                (() => {
                    if (showSnackbarMsg)
                        return (
                            <Snackbar
                                size="lg"
                                variant="solid"
                                color={"warning"}
                                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                                autoHideDuration={3000}
                                open={true}
                                onClose={() => {
                                    setShowSnackbarMsg(false);
                                }}
                                sx={{
                                    justifyContent: 'center',
                                    padding: '5px',
                                    whiteSpace: 'pre-line'
                                }}
                            >
                                {snackBarMsg}
                            </Snackbar>
                        )
                })()
            }
            {
                (() => {
                    if (showNotAvailableInMobileMsg) {
                        return (
                            <MobileAccessNotSupported onClose={() => {
                                setShowNotAvailableInMobileMsg(false);
                                setNavigationPathToHomeIfNeeded(IDWasInURL)
                                appDispatch(update(Page.HOME))
                            }}
                            />
                        )
                    }
                })()
            }
        </>
    )
}
